import React from "react";
import "./styles.css";
import image1 from "../../../assets/images/digital financing/digital_financing_4.jpg";
import image2 from "../../../assets/images/loan organization/businessmen-suits-seal-agreement-with-handshake-generated-by-ai.webp";
import image3 from "../../../assets/New Pics/loan_management_4.jpg";
import image4 from "../../../assets/images/loan-collection/calculating-finances-investing-future-wealth-generated-by-ai.webp";
import image5 from "../../../assets/images/leased inventory/leased-hero-section.webp";
import image6 from "../../../assets/New Pics/credit_engine_1.jpg";
import image7 from "../../../assets/New Pics/sme_loan_organization3.jpg";
import image8 from "../../../assets/New Pics/19728906869_d685b31f34_z.jpg";
import image9 from "../../../assets/New Pics/collateral mangemnet_3.jpg";
import image10 from "../../../assets/New Pics/reposession_module.jpg";
import image11 from "../../../assets/New Pics/legal_case_management.webp";
import image12 from "../../../assets/New Pics/treasure_management.jpg";
import image13 from "../../../assets/New Pics/costumer_service1.jpg";
import image14 from "../../../assets/New Pics/document_management2.jpg";
import image15 from "../../../assets/images/cyber-security/futurism-perspective-digital-nomads-lifestyle.webp";

import { useNavigate } from "react-router-dom";
const tempImage = "https://placehold.co/600x400";
const Index = () => {
  const navigate = useNavigate();
  const cities = [
    {
      city: "Digital Financing",
      description:
        "Unlock the future of financing with our cutting-edge digital financing solutions, delivering innovation and efficiency at fingertips.",
      image: image1,
      link: "/digital-financing",
    },
    {
      city: "Loan Origination",
      description:
        "Streamline your lending process with our advanced Loan Origination System, designed for efficiency and precision at every step.",
      image: image2,

      link: "/loan-origination",
    },
    {
      city: "Loan Management",
      description:
        "Optimize your lending operations with our comprehensive Loan Management System, ensuring seamless servicing and superior customer experience.",
      image: image3,

      link: "/loan-management",
    },
    {
      city: "Loan Collection",
      description:
        "Transforming debt collection into a clear path forward. Our Loan Collection System is the place where debts meet their match: efficient, effective, essential.",
      image: image4,

      link: "/loan-collection",
    },
    {
      city: "Leased Inventory Management",
      description:
        "Efficiently manage your leased inventory with our intuitive system. Track assets, streamline operations, and gain valuable insights to optimize performance and maximize ROI effortlessly.",
      image: image5,

      link: "/leased-inventory-management",
    },
    {
      city: "Credit Engine",
      description:
        "Make informed credit decisions with confidence using our advanced system. Harnessing cutting-edge analytics and data-driven insights, we empower businesses to assess risk, streamline approvals, and drive profitability.",
      image: image6,
      link: "/credit-engine",
    },
    {
      city: "SME Loan Origination",
      description:
        "Streamline your lending process for corporate customers with our Digital System, designed for efficiency and precision at every step.",
      image: image7,
      link: "/sme-loan-orgination",
    },
    {
      city: "AML & CF ",
      description:
        "- Utilizing advanced technology and regulatory expertise, we offer comprehensive solutions for compliance, risk assessment, and transaction monitoring ",
      image: image8,
      link: "/aml-cf",
    },
    {
      city: "Collateral Management",
      description:
        "From valuation and monitoring to risk mitigation, our platform offers comprehensive tools to enhance collateral management and minimize credit risk.",
      image: image9,
      link: "/collateral-management",
    },
    {
      city: "Repossession Module",
      description:
        "Comprehensive repossession automation system, designed to enhance efficiency..",
      image: image10,
      link: "/repossesion-management",
    },
    {
      city: "Legal Case Management",
      description:
        "A legal case management system streamlines case workflows, document management..",
      image: image11,
      link: "/legal-case-management",
    },
    {
      city: "Treasury Management",
      description:
        "- From Securitization and Portfolio management to cash flow forecasting and liquidity management, our platform empowers businesses to optimize critical financial operations.",
      image: image12,
      link: "/treasury-mangement",
    },
    {
      city: "Customer Service ",
      description:
        "Providing exceptional customer service, tailored to exceed your expectations.",
      image: image13,
      link: "/customer-service",
    },
    {
      city: "Document Management System",
      description:
        "Streamlining document management from all the modules in Lending solution with seamless organization and efficiency",
      image: image14,
      link: "/document-management",
    },
    {
      city: "Cyber Security",
      description:
        "Unlock the future of security with our cutting-edge cybersecurity solutions, delivering protection and peace of mind at your fingertips.",
      image: image15,
      link: "/Cyber-Security",
    },
  ];
  const handleServiceClick = (path) => {
    navigate(path);
  };
  return (
    <div id="Solutions-Section" className="GT">
      <section className="grid">
        {cities.map((city, index) => (
          <a
            key={index}
            className="card mxxx"
            onClick={() => handleServiceClick(city.link)}
            rel="nofollow noopener"
          >
            <picture className="card__picture">
              <img
                className="card__image"
                src={city.image}
                alt={city.city}
                title={city.city}
              />
            </picture>
            <span className="card__more"></span>
            <div className="card__content">
              <h3 className="card__title">{city.city}</h3>
              <p className="card__description sameP">{city.description}</p>
            </div>
          </a>
        ))}
      </section>
    </div>
  );
};

export default Index;
