import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useTextOpacityAnimation = (elementsRef) => {
  useEffect(() => {
    // Animate characters' opacity
    elementsRef.current.forEach((element) => {
      const chars = element.querySelectorAll(".char");
      gsap.fromTo(
        chars,
        { opacity: 0.2 },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: element,
            start: "top 100%",
            end: "bottom 50%",
            scrub: true,
          },
          stagger: 0.05, // Increase opacity character by character
        }
      );
    });
  }, [elementsRef]);
};

export default useTextOpacityAnimation;
