import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import image1 from "../../../assets/images/3d-shape-glowing-with-bright-holographic-colors.webp";
import image2 from "../../../assets/images/element-cer-01.svg";
import WhatsAppButton from "../../Utility/WhatsAppButton";
import { NavLink } from "react-router-dom";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
const Index = () => {
  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);
  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  return (
    <div id="About-Section" className={styles.Company}>
      <div className={styles.Company_ring}>
        <img
          src={image2}
          alt=""
          ref={(el) => (elementsRef.current[0] = el)}
          className={styles.fadein}
        />
      </div>
      <div className={styles.company_flex}>
        <div className={styles.content}>
          <h1
            ref={(el) => (elementsRef.current[1] = el)}
            className={`${styles.fadein} sameH`}
          >
            About Finnovator
          </h1>
          <p
            ref={(el) => (elementsRef.current[2] = el)}
            className={`${styles.fadein} sameP`}
          >
            {wrapCharactersInSpan(
              "Welcome to Finnovator IT Solutions, where innovation meets reliability in the realm of digital solutions & Services for the banking and financing sector. Established with a commitment to revolutionize the way financing institutions operate, we take pride in being at the forefront of technological advancements We envision a future where financing processes are seamless, secure, and efficient. We strive to empower banks and financing organizations with cutting-edge digital Solutions & Services that not only meet current industry demands but also anticipate and adapt to future challenges"
            )}
          </p>
          <div
            ref={(el) => (elementsRef.current[3] = el)}
            className={`${styles.fadein} ${styles.button}`}
          >
            <button className={` sameB`}>
              <NavLink to="contact">Contact US</NavLink>
            </button>
            <WhatsAppButton whatsappNumber="+971564452061" />
          </div>
        </div>
        <div>
          <img
            src={image1}
            alt="web-design"
            ref={(el) => (elementsRef.current[4] = el)}
            className={`${styles.fadein}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;

// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
