import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import one from "../../../assets/New Pics/DR and BCM.webp";
import two from "../../../assets/New Pics/Accepta-Blog-Julio-2.png";
import three from "../../../assets/images/loan organization/photorealistic-tree-money.webp";
import four from "../../../assets/New Pics/image-50.png";
import gsap from "gsap";
import SolutionsPage from "../../../components/HomePage/Gallery";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function LoadOrgination() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [
    "Apply for Financing For Personal Loan, Vehicle, Goods, Services, BNPL etc.",
    "Recent Requests’ Status",
    "Registered Suppliers Listing",
    "Assets lists from Registered Supplier",
    "Sanction List Screening",
    "Black List Screening",
    "Multiple Assets can be added to Application based on the configuration in the Scheme",
    "Configurable (Auto/Manual) Credit Approval based on defined Rules and Eligibility criteria",
  ];

  const leftPicTexts = [
    "Credit Decision with ability to use Deviation, Exceptions, Escalation and Review",
    "Systematic Offer generation based on selected Scheme / Offer",
    "Seamless Offer generation with multiple combination to be selected by Customer / Sales Staff",
    "Application related messaging between all Application’s related users",
    "Privileged users can communicate with customer directly using Mobile Application Notifications",
    "Third Party Interface Integration to perform KYC, credit check, Digital Signing, Trade, Asset Purchasing, Asset Registration, Insurance etc.",
    "Multiple Repayment schedule with different combinations of Grace Period, Deferred payment, Balloon, Bullet and Flexible Repayment schedule",
    "Segregated Repayment Schedule for each asset in order to accommodate individual Asset related management in post loan booking",
  ];

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>

      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Loan Origination")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            {rightPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 1] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[8] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[9] = el)}
              className={`${styles.fadein}`}
              src={four}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            {leftPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 10] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[18] = el)}
          className={`${styles.fadein} sameB `}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>

      <Footer />
    </div>
  );
}
