import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import F from "../../components/HomePage/F";
import Company from "../../components/HomePage/Company";
import Clients from "../../components/HomePage/Client";
import Industries from "../../components/HomePage/Industries";
import OptFor from "../../components/HomePage/OptFor";
import Animation from "../../components/HomePage/Animated/index.js";
import Video from "../../components/HomePage/Refrence";
import Gallery from "../../components/HomePage/Gallery";
import AdvanceTech from "../../components/HomePage/AdvanceTech";
import PortFolio from "../../components/HomePage/PortFolio";
import Accordian from "../../components/HomePage/Accordian";
import Services from "../../components/HomePage/Services";
import Contact from "../../components/HomePage/Contact";
import Footer from "../../components/HomePage/Footer";
import Solutions from "../../components/HomePage/Solutions";
import NavBar from "../../components/NavBar";
import Expertese from "../../components/HomePage/Expertese";
import wht from "../../assets/images/whatsapp.png";
import CaseStudies from "../../components/HomePage/CaseStudies/index.js";
export default function Index() {
  const scrollRef = useRef(null);

  const WhatsAppButton = ({ whatsappNumber }) => {
    const [message] = useState("Hello! I'd like to connect with you."); // Default message

    const handleClick = () => {
      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(whatsappUrl, "_blank");
    };

    return (
      <div className="whatsappWidget" onClick={handleClick}>
        <img src={wht} alt="" />
      </div>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      // This function will not be triggered because the overscrollEffect is disabled.
      // If you need to handle scroll events, you might need to use native event listeners.
      // However, if you only need to track the scroll speed, you can remove this useEffect.
    };

    return () => {
      // Clean up function
    };
  }, []);

  return (
    <div ref={scrollRef} className={`${styles.ct}`}>
      <NavBar />

      <div id="scroll-container">
        <F />
        <div className={`${styles.seven_p}`}>
          <Company />
          <Industries />
          <OptFor />
          <Services />
        </div>

        {/* <Video /> */}
        <Animation />

        <Gallery />
        <CaseStudies />
        <div className={`${styles.seven_p}`}>
          <Expertese />
        </div>
        <div className={`${styles.seven_p}`}>
          <AdvanceTech />
          <PortFolio />
          <Contact />
        </div>

        <Footer />
      </div>

      <WhatsAppButton whatsappNumber="+971564452061" />
    </div>
  );
}
