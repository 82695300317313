import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "./contnt.module.css";
import Footer from "../../../components/HomePage/Footer";
import Navbar from "../../../components/NavBar";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.main}>
        <div className={`${styles.main_most}`}>
          
          {/* First Content Block */}
          <div className={`${styles.main_in}`}>
            <div>
              <h
                ref={(el) => (elementsRef.current[0] = el)}
                className={`${styles.fadein}`}
              >
                We are hiring social media executive
              </h>
            </div>
            <div>
              <p
                ref={(el) => (elementsRef.current[42] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Doloremque, aliquid explicabo ex magni veritatis dolores illum
                doloribus dicta optio accusantium possimus id velit, rem nam
                sint quas magnam voluptas perspiciatis.
              </p>
              <p
                ref={(el) => (elementsRef.current[2] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Dicta, voluptatum.
              </p>
              <p
                ref={(el) => (elementsRef.current[3] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum dolor sit.
              </p>

              {/* First Apply Button */}
              <Link to="/apply-graphic-designer">
                <button
                  ref={(el) => (elementsRef.current[4] = el)}
                  className={`${styles.fadein}`}
                >
                  APPLY NOW - Social Media Executive
                </button>
              </Link>
            </div>
          </div>

          {/* Second Content Block */}
          <div className={`${styles.main_in}`}>
            <div>
              <h
                ref={(el) => (elementsRef.current[10] = el)}
                className={`${styles.fadein}`}
              >
                We are  hiring graphic designer
              </h>
            </div>
            <div>
              <p
                ref={(el) => (elementsRef.current[11] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Inventore, excepturi ullam vitae dolorum quas neque dolores
                recusandae omnis fuga doloribus sint adipisci tempore numquam
                voluptatum voluptate reiciendis voluptas.
              </p>
              <p
                ref={(el) => (elementsRef.current[12] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Eum, laudantium.
              </p>
              <p
                ref={(el) => (elementsRef.current[13] = el)}
                className={`${styles.fadein}`}
              >
                Lorem ipsum dolor sit.
              </p>

              {/* Second Apply Button */}
              <Link to="/apply-graphic-designer">
                <button
                  ref={(el) => (elementsRef.current[14] = el)}
                  className={`${styles.fadein}`}
                >
                  APPLY NOW - Graphic Designer
                </button>
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
