import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.css";
import WhatsAppButton from "../../Utility/WhatsAppButton";
import image12 from "../../../assets/MobileApp/2.png";
import image13 from "../../../assets/MobileApp/1.jpg";
import image14 from "../../../assets/flordia/logo.png";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;
      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);
    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility
    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const industries = [
    {
      Image: image12,
      link: "/WebApplication",
      title: "Web Application"
    },
    {
      Image: image13,
      link: "/MobileApplication",
      title: "Mobile Application"
    },
    {
      Image: image14,
      link: "/Flordia",
      title: "Flordia"
    },
  ];

  return (
    <div className={`${styles.Company}`}>
      <h1
        className={`${styles.title} ${styles.fadein} sameH`}
        ref={(el) => (elementsRef.current[0] = el)}
      >
        Case Studies
      </h1>
      <div className={`${styles.grid}`}>
        {industries.map((industry, index) => (
          <NavLink
            key={index}
            to={industry.link}
            className={`${styles.Image} ${styles.fadein}`}
            ref={(el) => (elementsRef.current[index + 1] = el)}
          >
            <div className={styles.imageWrapper}>
              <img src={industry.Image} alt={industry.title} />
              <h2 className={styles.heading}>{industry.title}</h2>
            </div>
          </NavLink>
        ))}
      </div>
      {/* <div className={`${styles.button}`}>
        <button className={`${styles.contactButton} sameB`}>
          <NavLink to="/contact">Contact Us</NavLink>
        </button>
        <WhatsAppButton whatsappNumber="+971564452061" />
      </div> */}
    </div>
  );
}

// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
