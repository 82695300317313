import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import defaultVideo from "../../../assets/3sec-mob.mp4";
import "./styles.css";
import defaultVideo2 from "../../../assets/3sec-mob.mp4";
gsap.registerPlugin(ScrollTrigger);

const Index = () => {
  useEffect(() => {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 1025px)", () => {
      console.log("Desktop animation");
      const tlDesktop = gsap.timeline({
        scrollTrigger: {
          trigger: ".content-scroll",
          start: "top 60%",
          end: "bottom center",
          scrub: 2,
          pin: true,
          // markers: true, // Enable markers for debugging, remove in production
        },
      });

      tlDesktop.to(".overlay-zoom", { scale: 1, duration: 2 });
      tlDesktop.to(".overlay-zoom", { scale: 1.2, duration: 2 });
      tlDesktop.to(".overlay-zoom", { scale: 4.4, duration: 2 });
      tlDesktop.to(".overlay-zoom", { scale: 6.7, duration: 2 });
      // tlDesktop.to(".overlay-zoom", { scale: 12.9, duration: 2 });
      // tlDesktop.to(".overlay-zoom", { scale: 12.5, duration: 2 });

      tlDesktop.to(".scroll-headline", { y: -20, opacity: 1 }, "<20%");
      tlDesktop.to(".scroll-des", { y: -20, opacity: 1 }, "<50%");
      tlDesktop.to(".scroll-button", { y: -20, opacity: 1 }, "<50%");
      tlDesktop.to(".overlay-zoom", { opacity: 0, duration: 1 }, ">");
    });

    mm.add("(max-width: 1024px)", () => {
      console.log("Mobile animation");
      const tlMobile = gsap.timeline({
        scrollTrigger: {
          trigger: ".content-scroll",
          start: "top 50%", // Adjust the start position for mobile
          end: "bottom center",
          scrub: 1,
          pin: true,
        },
      });

      tlMobile.to(".overlay-zoom", { scale: 1, duration: 1 });
      tlMobile.to(".overlay-zoom", { scale: 2, duration: 1 });
      tlMobile.to(".overlay-zoom", { scale: 4, duration: 1 });

      tlMobile.to(".overlay-zoom", { scale: 8, duration: 1 });
      tlMobile.to(".scroll-headline", { y: -20, opacity: 1 }, "<20%");
      tlMobile.to(".scroll-des", { y: -20, opacity: 1 }, "<50%");
      tlMobile.to(".scroll-button", { y: -20, opacity: 1 }, "<50%");
      tlMobile.to(".overlay-zoom", { opacity: 0, duration: 1 }, ">");
    });

    // Cleanup
    return () => {
      mm.revert(); // Revert all media queries and associated animations
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="scroll-container">
      <video className="background-video ds-v" autoPlay loop muted>
        <source src={defaultVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video className="background-video mb-v" autoPlay loop muted>
        <source src={defaultVideo2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h1>OUR SOLUTIONS</h1>

      <div className="overlay-zoom"></div>
      <div className="content-scroll">
        <div className="scroll-des">
          {/* <video autoPlay muted loop>
            <source src={defaultVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
