// src/components/Preloader.js
import React from 'react';
import './Preloader.css'; // Add your preloader styles here

const Preloader = () => (
	<div class="container">
		<div class="loader"><span></span></div>
		<div class="loader"><span></span></div>

		<div class="loader"><i></i></div>
		<div class="loader"><i></i></div>
	</div>
);

export default Preloader;
