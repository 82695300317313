import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import one from "../../../assets/images/information technology/computer-network-computer-security.webp";
import two from "../../../assets/images/information technology/futurism-perspective-digital-nomads-lifestyle (1).webp";
import three from "../../../assets/images/information technology/futurism-perspective-digital-nomads-lifestyle.webp";
import four from "../../../assets/images/information technology/person-using-ar-technology-their-daily-occupation.webp";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import SolutionsPage from "../../../components/HomePage/Gallery";
import gsap from "gsap";
import OurProcess from "../../../components/HomePage/OurProcess";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// const one = "https://placehold.co/600x400";
// const two = "https://placehold.co/1600x800";

export default function InformationTechnology() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);
  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top", // Start animation when image enters the center of the viewport
        end: "bottom center", // End animation when image leaves the center of the viewport
        scrub: true, // Smoothly animates the opacity based on scroll position
      },
    });
  }, []);
  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={four}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan(" IT Services")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[1] = el)}
              className={`${styles.fadein} sameHh`}
              id={`${styles.fHh}`}
            >
              {wrapCharactersInSpan("Digital Transformation")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[2] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "We help you harness the power of emerging technologies tomodernize your operations, enhance customer experiences, and stay ahead of the curve in a rapidly evolving digital landscape."
              )}
            </p>
            <ul>
              <li
                ref={(el) => (elementsRef.current[3] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Business Process Automation")}
              </li>
              <li
                ref={(el) => (elementsRef.current[4] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Robotic Process Automation")}
              </li>
              <li
                ref={(el) => (elementsRef.current[5] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Business Intelligence")}
              </li>
              <li
                ref={(el) => (elementsRef.current[6] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(" Data-Driven Decision Making")}
              </li>
              <li
                ref={(el) => (elementsRef.current[7] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Customer Experience Enhancement")}
              </li>
              <li
                ref={(el) => (elementsRef.current[8] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(
                  " Forward Integration through Digital Channels"
                )}
              </li>
            </ul>
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[9] = el)}
              className={`${styles.fadein}`}
              src={three}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[10] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[11] = el)}
              className={`${styles.fadein} sameHh`}
              id={`${styles.fHh}`}
            >
              {wrapCharactersInSpan("IT Services Management")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[12] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "  IT service management (ITSM) encompasses various key tasks aimed at delivering and managing IT services effectively to meet the needs of the business and its users. Finnovator IT Solution can help organizations in enhancing IT service management by providing;"
              )}
            </p>
            <ul>
              <li
                ref={(el) => (elementsRef.current[13] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(" IT Service Governance")}
              </li>
              <li
                ref={(el) => (elementsRef.current[14] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Continual Service Improvement (CSI)")}
              </li>
              <li
                ref={(el) => (elementsRef.current[15] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Service Level Management")}
              </li>
              <li
                ref={(el) => (elementsRef.current[16] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(" Change Management")}
              </li>
              <li
                ref={(el) => (elementsRef.current[17] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("  Incident Management")}
              </li>
              <li
                ref={(el) => (elementsRef.current[18] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Asset Management")}
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[19] = el)}
              className={`${styles.fadein} sameHh`}
              id={`${styles.fHh}`}
            >
              {wrapCharactersInSpan("Technology Advisory Service")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[20] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                " Make Informed Technology Decisions and Achieve Your Strategic Goals Our Technology Advisory Services provide expert guidance on technology selection, implementation, and optimization, ensuring alignment with your business objectives."
              )}
            </p>
            <ul>
              <li
                ref={(el) => (elementsRef.current[21] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Minimize technology investment risks")}
              </li>
              <li
                ref={(el) => (elementsRef.current[22] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(
                  "Accelerate technology adoption and time-to-value"
                )}
              </li>
              <li
                ref={(el) => (elementsRef.current[23] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(
                  "Drive innovation and competitive advantage"
                )}
              </li>
            </ul>
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[24] = el)}
              className={`${styles.fadein}`}
              src={two}
              alt=""
            />
          </div>
        </div>
        <button
          ref={(el) => (elementsRef.current[25] = el)}
          className={`${styles.fadein} sameB`}
        >
          Get in touch
        </button>
        <OurProcess />
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
