import React from "react";
import styles from "./about.module.css";
import WeAre from "../../components/AboutPage/WeAre";
import WeAreCom from "../../components/AboutPage/WeAreCompany";
import WeBel from "../../components/AboutPage/WeBelieve";
import WeCare from "../../components/AboutPage/WeCare";
import Animated from "../../components/AboutPage/Animated";
import Footer from "../../components/HomePage/Footer";
import Navbar from "../../components/NavBar";
export default function index() {
  return (
    <div>
      <Navbar />
      <WeAre />
      <WeAreCom />
      <WeBel />
      <WeCare />
      <Animated />
      <Footer />
    </div>
  );
}
