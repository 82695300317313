import React from "react";
import Career from "../../components/Careers/Career";
import Content from "../../components/Careers/Content";
export default function Index() {
  return (
    <div>
      <Career />
      <Content />
    </div>
  );
}
