import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import styles from "./styles.module.css";

const Index = () => {
  const elementsRef = useRef([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    topic: "", // Add a new key for dropdown value
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    }

    if (!formData.company) {
      newErrors.company = "Company is required";
    }

    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    if (!formData.topic) {
      newErrors.topic = "Topic selection is required";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    emailjs
      .sendForm(
        "service_l1oqqdl",
        "template_qgpor83",
        e.target,
        "7niUikV-zazSzHAsJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully");
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
            topic: "", // Reset dropdown value
          });
          setErrors({});
        },
        (error) => {
          console.log(error.text);
          alert("Error sending email");
        }
      );
  };

  return (
    <div id="Contact-Section" className={`${styles.M_C}`}>
      <h3
        className={`${styles.cH} sameH ${styles.fadein}`}
        ref={(el) => (elementsRef.current[0] = el)}
      >
        Let talk about your <br />
        requirements.
      </h3>
      <div className={`${styles.contactForm}`}>
        <form onSubmit={handleSubmit}>
          <ul>
            {["Name", "Email", "Phone", "Company"].map((placeholder, index) => (
              <li
                key={index}
                className={`${styles.haifW} ${styles.fadein}`}
                ref={(el) => (elementsRef.current[index + 1] = el)}
              >
                <input
                  type="text"
                  name={placeholder.toLowerCase()}
                  placeholder={placeholder}
                  value={formData[placeholder.toLowerCase()]}
                  onChange={handleChange}
                />
                {errors[placeholder.toLowerCase()] && (
                  <span className={styles.error}>
                    {errors[placeholder.toLowerCase()]}
                  </span>
                )}
              </li>
            ))}
            <li
              className={`${styles.fullW} ${styles.fadein}`}
              ref={(el) => (elementsRef.current[6] = el)}
            >
              <div className={`${styles.fullW} ${styles.custom_select}`}>
                <select
                  name="topic"
                  value={formData.topic}
                  onChange={handleChange}
                >
                  <option value="">Select a topic</option>
                  <option value="PostDesgning">
                    Logo & Social Media Post Designing
                  </option>
                  <option value="Ui/UxDesgning">Ui / Ux Designing</option>
                  <option value="WebApplicationDevelopment">
                    Web Application Development
                  </option>
                  <option value="MobileApplication">Mobile Application </option>
                  <option value="WebsiteDevelopment">
                    Website Development
                  </option>
                  <option value="CyberSecurity">Cyber Security</option>
                  <option value="Others">Others</option>
                </select>
                {errors.topic && (
                  <span className={styles.error}>{errors.topic}</span>
                )}
              </div>
            </li>
            <li
              className={`${styles.fullW} ${styles.fadein}`}
              ref={(el) => (elementsRef.current[5] = el)}
            >
              <textarea
                name="message"
                placeholder="Type your message here..."
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <span className={styles.error}>{errors.message}</span>
              )}
            </li>
          </ul>
          <div
            className={`${styles.btn} ${styles.fadein}`}
            ref={(el) => (elementsRef.current[7] = el)}
          >
            <button type="submit">Send inquiry</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;

// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
