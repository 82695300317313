import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import one from "../../../assets/images/leased inventory/calculating-finances-investing-future-wealth-generated-by-ai.webp";
import two from "../../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import three from "../../../assets/images/leased inventory/leased-hero-section.webp";
import four from "../../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import gsap from "gsap";
import SolutionsPage from "../../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function LeasedInventoroyManagement() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [
    "Managing tangible assets financed through leasing products",
    "Manage and maintain the details of the Assets along with the status",
    "Periodic evaluation and inspection feature",
    "Insurance related information",
    "Registration and other legal documentation and renewal related information",
    "Custody of the asset along with expected location (region, city)",
    "Ownership Information",
    "Traffic penalty details",
    "Ownership transfer details",
  ];

  const leftPicTexts = [
    "Manage and maintain all the insurance contracts with insurance company",
    "Individual insurance against each asset as per the contract",
    "Renewal of insurance contracts",
    "Renewal of insurance for individual assets",
    "Allocation of Insurance receivables / Payables to customer contracts",
    "Insurance Claim management",
  ];

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Leased Inventoroy Management")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[1] = el)}
              className={`${styles.fadein}`}
              id={`${styles.fHh} sameHh`}
            >
              {wrapCharactersInSpan("Inventory Management")}
            </h1>

            {rightPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 2] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[11] = el)}
              className={`${styles.fadein}`}
              src={four}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[12] = el)}
              className={`${styles.fadein}`}
              src={three}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[13] = el)}
              className={`${styles.fadein}`}
              id={`${styles.fHh} sameHh`}
            >
              {wrapCharactersInSpan("Insurance Management")}
            </h1>

            {leftPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 14] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[20] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
