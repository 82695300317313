import React from "react";
import styles from "./ourProcess.module.css";
import img1 from "../../../assets/images/our process/1.webp";
import img2 from "../../../assets/images/our process/files-sent-concept-illustration.webp";
import img3 from "../../../assets/images/our process/gradient-illustration-international-internet-day-celebration.webp";
import img4 from "../../../assets/images/our process/hand-drawn-flat-design-sql-illustration.webp";
const tempImage = "https://placehold.co/600x400";

export default function index() {
  return (
    <div className={`${styles.ourProcess}`}>
      <h2 className={`${styles.centerH} sameH`}>Our Process</h2>
      <p className={`${styles.centerP} sameP`}>
        Partner with a Top-Rated Web Design Company & Digital Agency with more
        than 15 years in business, we’ve developed a proven process for
        achieving success for every one of our clients.
      </p>
      <div className={`${styles.sections}`}>
        <div className={`${styles.proFlexLeft} ${styles.one}`}>
          <div className={`${styles.proFlexLeftItem}`}>
            <img src={img1} alt="" />
          </div>
          <div className={`${styles.proFlexLeftItem}`}>
            <h3 className="sameHh">Discovery</h3>
            <p className="sameP">
              We start our projects by understanding the scope and requirements.
              This is accomplished by working closely with you to make sure we
              are all on the same page.
            </p>
          </div>
        </div>
        <div className={`${styles.proFlexLeft}`}>
          <div className={`${styles.proFlexLeftItem}`}>
            <h3 className="sameHh">Plan</h3>
            <p className="sameP">
              Following the initial kick-off meeting, we will outline your
              project, create milestones, and agree on project priorities. Now
              we have a strategic plan in place that aligns with your initial
              vision and makes your goals achievable.
            </p>
          </div>
          <div className={`${styles.proFlexLeftItem}`}>
            <img src={img2} alt="" />
          </div>
        </div>
        <div className={`${styles.proFlexLeft} ${styles.three}`}>
          <div className={`${styles.proFlexLeftItem}`}>
            <img src={img3} alt="" />
          </div>
          <div className={`${styles.proFlexLeftItem}`}>
            <h3 className="sameHh">Execute</h3>
            <p className="sameP">
              The final design takes shape and, at this stage, the idea comes to
              life representing the visual concepts. Our creative development
              team reviews and revises the materials until it aligns with your
              goals.
            </p>
          </div>
        </div>
        <div className={`${styles.proFlexLeft} ${styles.bk}`}>
          <div className={`${styles.proFlexLeftItem}`}>
            <h3 className="sameHh">Deliver</h3>
            <p className="sameP">
              Review and testing takes place, which ensures the quality of your
              project. We value your reputation and want to make sure it is
              correct. After this, we present your finished custom project and
              upon approval, your new website will be launched and promoted.
            </p>
          </div>
          <div className={`${styles.proFlexLeftItem}`}>
            <img src={img4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
