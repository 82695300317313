import React, { useEffect, useRef } from "react";
import styles from "./WeCare.module.css";
const image = "https://placehold.co/600x400";
export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`${styles.coreS}`}>
        <div className={`${styles.c_item}`}>
          <div className={`${styles.items}`}>
            <h1
              ref={(el) => (elementsRef.current[0] = el)}
              className={`${styles.fadein} ${styles.big} sameH`}
            >
              We Care
            </h1>
          </div>
          <div className={`${styles.items}`}>
            <img
              ref={(el) => (elementsRef.current[1] = el)}
              className={`${styles.fadein}`}
              src={image}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
