import React, { useEffect, useRef } from "react";
import listimage from "../../../assets/images/list-icn-n.svg";
import listbackground from "../../../assets/images/element-cer-02.svg";
import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const industries = [
    {
      title: "Banking & Finance",
      description:
        "Streamline, secure, and succeed in digital financing with our comprehensive Lending Solution, designed for end-to-end management.",
    },
    {
      title: "Healthcare",
      description:
        "- Revolutionize patient care with our Digital & integrated healthcare solution, offering comprehensive modules for every aspect of Healthcare",
    },
    {
      title: "Retail & Ecommerce",
      description:
        "Transform your business with our comprehensive digitalsolutions, tailored to enhance customer experience and drive sales",
    },
    {
      title: "Real Estate & Construction",
      description:
        "Attract & engage potential buyers and investors with our cutting-edge technology, tailored to showcase your project with stunning visuals and seamless functionality",
    },
    
  ];

  return (
    <div className={`${styles.container}`}>
      <div />
      <div className={`${styles.content}`}>
        <div className={`${styles.heading}`}>
          <h3
            className={`${styles.title} ${styles.fadein} sameH`}
            ref={(el) => (elementsRef.current[0] = el)}
          >
            Industries We Cater
          </h3>
        </div>
        <div className={`${styles.grid}`}>
          {industries.map((industry, index) => (
            <div
              key={index}
              className={`${styles.card} ${styles.fadein}`}
              ref={(el) => (elementsRef.current[index + 1] = el)}
            >
              {index === 0 && (
                <img
                  src={listbackground}
                  alt=""
                  className={`${styles.backgroundImage}`}
                />
              )}
              <div className={`${styles.cardContent}`}>
                <img src={listimage} alt="" className={`${styles.icon} `} />
                <div>
                  <h3 className={`${styles.cardTitle}  sameI`}>
                    {industry.title}
                  </h3>
                  <p className={`${styles.description}  sameD`}>
                    {industry.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`${styles.button}`}>
          <button className={`${styles.contactButton} ${styles.fadein} sameB`}>
            <NavLink to="/contact">Contact Us</NavLink>
          </button>
        </div>
      </div>
    </div>
  );
}

// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
