import { useState } from "react";

const WhatsAppButton = ({ whatsappNumber }) => {
  const [message] = useState("Hello! I'd like to connect with you."); // Default message

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <button className={` sameB`} onClick={handleClick}>
      Call Us
    </button>
  );
};

export default WhatsAppButton;
