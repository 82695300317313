import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import four from "../../../assets/images/business continuity/scene-with-business-person-working-futuristic-office-job.webp";
import two from "../../../assets/images/business continuity/business-person-futuristic-business-environment.webp";
import three from "../../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import one from "../../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import gsap from "gsap";
import SolutionsPage from "../../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function RepossesionMang() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [
    "Comprehensive repossession automation system, designed to enhance efficiency and accuracy in managing repossessed assets",
    " Our web-based platform integrates essential modules including the E-Commerce Portal, Auction Portal, Showroom Portal, and Third Party Access Portal, all underpinned by a robust Repossession Module. ",
    " Whether you're an individual seeking seamless e-commerce transactions or a commercial entity such as a towing service provider, inspection and evaluation service, or logistic vendor, our system provides tailored solutions to meet your needs. ",
  ];

  const leftPicTexts = [
    "Each module operates within a structured workflow framework, ensuring adherence to policies and granting appropriate rights and privileges for smooth operational management. ",
    "Discover how our system can transform your repossession processes with precision and ease.",
  ];

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Repossesion Management")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[1] = el)}
              className={`${styles.fadein}`}
              id={`${styles.fHh} sameHh`}
            >
              {/* {wrapCharactersInSpan("Lorem ipsum")} */}
            </h1>

            {rightPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 2] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[11] = el)}
              className={`${styles.fadein}`}
              src={four}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[12] = el)}
              className={`${styles.fadein}`}
              src={three}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            <h1
              ref={(el) => (elementsRef.current[13] = el)}
              className={`${styles.fadein}`}
              id={`${styles.fHh} sameHh`}
            >
              {/* {wrapCharactersInSpan("Lorem ipsum")} */}
            </h1>

            {leftPicTexts.map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 14] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[20] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
