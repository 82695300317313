import React from "react";
import Navbar from "../../components/NavBar";
import Contact from "../../components/Contact/Contact";
import Form from "../../components/Contact/Form";
import Footer from "../../components/HomePage/Footer";
export default function index() {
  return (
    <div>
      <Navbar />
      <Contact />
      <Form />

      <Footer />
    </div>
  );
}
