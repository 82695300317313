import React, { useEffect, useRef } from "react";
import styles from "./brands.module.css";
import image from "../../../assets/images/element-cer-02.svg";
import Footer from "../../HomePage/Footer";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <section className={`${styles.brand}`}>
        <div>
          <div
            className={`${styles.bg}`}
            ref={(ref) => (elementsRef.current[0] = ref)}
          >
            <div className={`${styles.brand_cont}`}>
              <div ref={(ref) => (elementsRef.current[1] = ref)}>
                <h1>Immersive Brands</h1>
                <p>
                  Catering to Saudi Arabia, we elevate your brand's essence by
                  crafting a visual and verbal symphony that resonates, making
                  your brand unforgettable.
                </p>
                <h5>CAPABILITIES</h5>
                <ul>
                  <li>Brand Strategy</li>
                  <li>Brand Positioning</li>
                  <li>Brand Architecture</li>
                  <li>Brand Audit</li>
                  <li>Brand Naming</li>
                  <li>Content Framework</li>
                  <li>Identity (Logo Design)</li>
                  <li>Design System</li>
                  <li>Brand Guidelines</li>
                  <li>Voice and Tone</li>
                  <li>Copywriting</li>
                  <li>Video Production</li>
                  <li>Packaging Design</li>
                  <li>Presentations</li>
                  <li>Animation</li>
                  <li>Brochures</li>
                  <li>Brand Management</li>
                </ul>

                <ul className={`${styles.ful}`}>
                  <li>VIEW DETAILS</li>

                  <li>VIEW PORTFOLIO</li>
                </ul>
              </div>
              <div></div>
              <img className={styles.rel} src={image} alt="" />
            </div>
          </div>
          <div
            className={`${styles.bgg}`}
            ref={(ref) => (elementsRef.current[2] = ref)}
          >
            <div
              className={`${styles.bg}`}
              ref={(ref) => (elementsRef.current[3] = ref)}
            >
              <div className={`${styles.brand_cont}`}>
                <div></div>
                <div ref={(ref) => (elementsRef.current[4] = ref)}>
                  <h1>Immersive Brands</h1>
                  <p>
                    Catering to Saudi Arabia, we elevate your brand's essence by
                    crafting a visual and verbal symphony that resonates, making
                    your brand unforgettable.
                  </p>
                  <h5>CAPABILITIES</h5>
                  <ul>
                    <li>Brand Strategy</li>
                    <li>Brand Positioning</li>
                    <li>Brand Architecture</li>
                    <li>Brand Audit</li>
                    <li>Brand Naming</li>
                    <li>Content Framework</li>
                    <li>Identity (Logo Design)</li>
                    <li>Design System</li>
                    <li>Brand Guidelines</li>
                    <li>Voice and Tone</li>
                    <li>Copywriting</li>
                    <li>Video Production</li>
                    <li>Packaging Design</li>
                    <li>Presentations</li>
                    <li>Animation</li>
                    <li>Brochures</li>
                    <li>Brand Management</li>
                  </ul>

                  <ul className={`${styles.ful}`}>
                    <li>VIEW DETAILS</li>

                    <li>VIEW PORTFOLIO</li>
                  </ul>
                </div>
                <img className={styles.rel} src={image} alt="" />
              </div>
            </div>

            <div
              className={`${styles.bg} `}
              ref={(ref) => (elementsRef.current[5] = ref)}
            >
              <div className={`${styles.brand_cont}`}>
                <div ref={(ref) => (elementsRef.current[6] = ref)}>
                  <h1>Immersive Brands</h1>
                  <p>
                    Catering to Saudi Arabia, we elevate your brand's essence by
                    crafting a visual and verbal symphony that resonates, making
                    your brand unforgettable.
                  </p>
                  <h5>CAPABILITIES</h5>
                  <ul>
                    <li>Brand Strategy</li>
                    <li>Brand Positioning</li>
                    <li>Brand Architecture</li>
                    <li>Brand Audit</li>
                    <li>Brand Naming</li>
                    <li>Content Framework</li>
                    <li>Identity (Logo Design)</li>
                    <li>Design System</li>
                    <li>Brand Guidelines</li>
                    <li>Voice and Tone</li>
                    <li>Copywriting</li>
                    <li>Video Production</li>
                    <li>Packaging Design</li>
                    <li>Presentations</li>
                    <li>Animation</li>
                    <li>Brochures</li>
                    <li>Brand Management</li>
                  </ul>

                  <ul className={`${styles.ful}`}>
                    <li>VIEW DETAILS</li>

                    <li>VIEW PORTFOLIO</li>
                  </ul>
                </div>
                <div></div>
                <img className={styles.rel} src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.cb}`}>
          <h3 ref={(ref) => (elementsRef.current[7] = ref)}>
            Lorem ipsum, dolor sit amet consectetur{" "}
          </h3>
          <p ref={(ref) => (elementsRef.current[8] = ref)}>
            Lorem ipsum dolor sit amet.
          </p>
          <button ref={(ref) => (elementsRef.current[9] = ref)}>Contact</button>
        </div>
      </section>
      <Footer />
    </div>
  );
}
