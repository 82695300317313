import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import Gallery from "../../HomePage/Gallery";
import Footer from "../../../components/HomePage/Footer";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`${styles.content}`}
        ref={(ref) => (elementsRef.current[0] = ref)}
      >
        <h2 ref={(ref) => (elementsRef.current[1] = ref)}>
          Case <br /> Studies
        </h2>
        <h5 ref={(ref) => (elementsRef.current[2] = ref)}>
          Lorem ipsum dolor sit amet.
        </h5>
      </div>
      <Gallery />
      <div
        className={`${styles.content_below}`}
        ref={(ref) => (elementsRef.current[3] = ref)}
      >
        <div className={`${styles.txt}`}>
          <h3 ref={(ref) => (elementsRef.current[4] = ref)}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nemo
            reprehenderit laboriosam qui itaque recusandae?
          </h3>
        </div>
      </div>
      <Footer />
    </div>
  );
}
