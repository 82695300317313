import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Decoration from "./Decoration";
import bgVideo from "../../../assets/reverse.mp4";

export default function Index() {
  const [opacity, setOpacity] = useState(1);
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newOpacity = Math.max(1 - scrollY / 300, 0);
      setOpacity(newOpacity);

      const newOverlayOpacity = Math.min(scrollY / 300, 1);
      setOverlayOpacity(newOverlayOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={styles.backgroundVideoContainer}>
        <video
          className={styles.backgroundVideo}
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <Decoration /> */}
        <div
          className={styles.blackOverlay}
          style={{ opacity: overlayOpacity }}
        ></div>
        <div className={styles.banner}>
          <h3>
            <span>FINNOVATOR</span>
          </h3>
          <div className={styles.services_listed}>
            <p>IT SOLUTIONS</p>
            
          </div>
        </div>
      </div>
    </>
  );
}
