import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import styles from "./jobApplication.module.css";

const JobApplication = () => {
  const formRef = useRef(null); // Create ref for form
  const elementsRef = useRef([]);
  const [resume, setResume] = useState(null);
  const [resumePreview, setResumePreview] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    previousPosition: "",
    applyingFor: "",
    experience: "",
    linkedin: "", // New field
    portfolio: "", // New field
    expectedSalary: "", // New field
    availability: "", // New field
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // Add success message state

  useEffect(() => {
    const handleScroll = () => {
      const tags = elementsRef.current;
      tags.forEach((tag) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);
    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input is a file
    if (name === "resume") {
      const file = e.target.files[0];
      if (file) {
        setResume(file);
        setResumePreview(file.name); // Optionally store the file name for preview
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.previousPosition) newErrors.previousPosition = "Previous Position is required";
    if (!formData.applyingFor) newErrors.applyingFor = "Applying For is required";
    if (!formData.experience) newErrors.experience = "Experience is required";
    if (!formData.linkedin) newErrors.linkedin = "LinkedIn profile is required";
    if (!formData.portfolio) newErrors.portfolio = "Portfolio link is required";
    if (!formData.expectedSalary) newErrors.expectedSalary = "Expected salary is required";
    if (!formData.availability) newErrors.availability = "Availability is required";
    if (!resume) newErrors.resume = "Resume is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("previousPosition", formData.previousPosition);
    formDataToSend.append("applyingFor", formData.applyingFor);
    formDataToSend.append("experience", formData.experience);
    formDataToSend.append("linkedin", formData.linkedin);
    formDataToSend.append("portfolio", formData.portfolio);
    formDataToSend.append("expectedSalary", formData.expectedSalary);
    formDataToSend.append("availability", formData.availability);
    formDataToSend.append("resume", resume);

    emailjs
      .sendForm(
        "service_l1oqqdl",
        "template_oufkdjf",
        formRef.current,
        "7niUikV-zazSzHAsJ"
      )
      .then(
        (result) => {
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            previousPosition: "",
            applyingFor: "",
            experience: "",
            linkedin: "",
            portfolio: "",
            expectedSalary: "",
            availability: "",
          });
          setResume(null);
          setResumePreview("");
          setErrors({});
          setSuccessMessage("Your application has been submitted successfully!");
        },
        (error) => {
          alert("Error sending email: " + error.text);
        }
      );
  };

  function throttle(fn, wait) {
    let time = Date.now();
    return function () {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.jobApplicationForm}>
        <div className={styles.leftContent}>
          <h3 ref={(el) => (elementsRef.current[0] = el)} className={styles.fadeIn}>
            Apply For Job Here
          </h3>
          <p ref={(el) => (elementsRef.current[1] = el)} className={styles.fadeIn}>
            Please fill in the details below
          </p>
        </div>

        <div className={styles.rightForm}>
          <form ref={formRef} onSubmit={handleSubmit}>
            <ul>
              {[
                "fullName", "email", "phone", "previousPosition", "applyingFor", "experience",
                "linkedin", "portfolio", "expectedSalary", "availability"
              ].map((name, index) => (
                <li key={index} ref={(el) => (elementsRef.current[index + 1] = el)}>
                  <input
                    type="text"
                    placeholder={name.split(/(?=[A-Z])/).join(" ")}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                  />
                  {errors[name] && <span className={styles.error}>{errors[name]}</span>}
                </li>
              ))}
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <label className={styles.uploadButton}>
                  Upload Resume
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    className={styles.fileInput}
                    onChange={handleChange}
                    name="resume"
                  />
                </label>
                {errors.resume && <span className={styles.error}>{errors.resume}</span>}
                {resumePreview && <span>{resumePreview}</span>}
              </li>
            </ul>
            <button ref={(el) => (elementsRef.current[12] = el)}>
              Submit Application
            </button>
            {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobApplication;
