import React, { useEffect, useState } from "react";
import styles from "./weAre.module.css";

import image1 from "../../../assets/images/about_bi_05.webp";
import image2 from "../../../assets/images/about_bi_06.webp";
import image3 from "../../../assets/images/about_bi_04.webp";
import image4 from "../../../assets/images/about_bi_01.webp";
import image5 from "../../../assets/images/slide-1-anim.png";
import image6 from "../../../assets/images/about_bi_03.webp";
export default function Index() {
  const [opacity, setOpacity] = useState(1);
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newOpacity = Math.max(1 - scrollY / 50, 0);
      setOpacity(newOpacity);

      const newOverlayOpacity = Math.min(scrollY / 50, 1);
      setOverlayOpacity(newOverlayOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.F}>
      <div
        className={styles.blackOverlay}
        style={{ opacity: overlayOpacity }}
      ></div>
      <div className={styles.banner}>
        <div className={styles.backgroundContainer} style={{ opacity }}>
          <img
            src={image1}
            className={`${styles.image} ${styles.image1}`}
            alt="Image 1"
          />
          <img
            src={image2}
            className={`${styles.image} ${styles.image2}`}
            alt="Image 2"
          />
          <img
            src={image3}
            className={`${styles.image} ${styles.image3}`}
            alt="Image 3"
          />
          <img
            src={image6}
            className={`${styles.image} ${styles.image6}`}
            alt="Image 6"
          />
          <img
            src={image4}
            className={`${styles.image} ${styles.image4}`}
            alt="Image 4"
          />
          <img src={image5} className={`${styles.image5}`} alt="Image 5" />
        </div>
        <h3>WE ARE</h3>
      </div>
    </div>
  );
}
