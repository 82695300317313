import React, { useEffect, useRef } from "react";
import styles from "./weBelieve.module.css";
import image from "../../../assets/images/element-cer-02.svg";
const image2 = "https://placehold.co/600x400";
export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`${styles.WeBel}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.eg} ${styles.big} sameH`}
        >
          We Believe
        </h1>
        <h5 ref={(el) => (elementsRef.current[1] = el)} className={styles.eg}>
          Lorem ipsum dolor sit. Lorem, ipsum. <br /> Lorem, ipsum dolor.
        </h5>
        <div className={`${styles.weItem}`}>
          <div className={`${styles.weItems}`}>
            <h1
              ref={(el) => (elementsRef.current[2] = el)}
              className={styles.eg}
            >
              Lorem ipsum dolor sit. Lorem. Lorem, ipsum.
            </h1>
          </div>
          <div className={`${styles.weItems}`}>
            <h1
              ref={(el) => (elementsRef.current[3] = el)}
              className={styles.eg}
            >
              Lorem ipsum dolor sit.
            </h1>
          </div>
          <div className={`${styles.weItems}`}>
            <h1
              ref={(el) => (elementsRef.current[4] = el)}
              className={styles.eg}
            >
              Lorem ipsum dolor sit amet. Lorem, ipsum dolor.
            </h1>
          </div>
          <div className={`${styles.weItems}`}>
            <h1
              ref={(el) => (elementsRef.current[5] = el)}
              className={styles.eg}
            >
              Lorem ipsum dolor sit.
            </h1>
          </div>
          <div className={`${styles.weItems}`}>
            <h1
              ref={(el) => (elementsRef.current[6] = el)}
              className={styles.eg}
            >
              Lorem, ipsum dolor. Lorem, ipsum dolor.
            </h1>
          </div>
        </div>
        <img className={styles.rel} src={image} alt="" />
        <div className={`${styles.coreS}`}>
          <div className={`${styles.c_item}`}>
            <div className={`${styles.items}`}>
              <h1
                ref={(el) => (elementsRef.current[7] = el)}
                className={`${styles.eg} sameH`}
              >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel
                optio iure commodi? Aut eveniet culpa, iusto ex in non id.
              </h1>
            </div>
            <div className={`${styles.items}`}>
              <img
                ref={(el) => (elementsRef.current[8] = el)}
                className={styles.eg}
                src={image2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
