import React from "react";
import What from "../../components/WhatWeDo/What";
import Navbar from "../../components/NavBar";
import Brands from "../../components/WhatWeDo/Brands";
export default function Index() {
  return (
    <div>
      <Navbar />
      <What />
      <Brands />
    </div>
  );
}
