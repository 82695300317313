import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import one from "../../../assets/images/3d-shape-glowing-with-bright-holographic-colors.webp";
import two from "../../../assets/New Pics/trendemon_A_blue_and_fuchsia_isometric_hd_illustration_of_a_fu_1a5e75d5-bccc-4cd0-ae34-d8b8d218e63b.png";
import three from "../../../assets/images/business continuity/business-person-futuristic-business-environment.webp";
import four from "../../../assets/images/business continuity/scene-with-business-person-working-futuristic-office-job.webp";
import OurProcess from "../../../components/HomePage/OurProcess";
import SolutionsPage from "../../../components/HomePage/Gallery";

gsap.registerPlugin(ScrollTrigger);

export default function BusinessContinuity() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top", // Start animation when image enters the center of the viewport
        end: "bottom center", // End animation when image leaves the center of the viewport
        scrub: true, // Smoothly animates the opacity based on scroll position
      },
    });
  }, []);

  const serviceContent = [
    {
      title: "Business Impact Analysis (BIA)",
      description:
        "Conducting a thorough assessment of your organization's business processes, dependencies, and critical functions to identify potential impacts of disruptions. Analyzing the financial, operational, and reputational consequences of downtime to prioritize recovery efforts and resource allocation.",
    },
    {
      title: "Business Continuity Planning (BCP)",
      description:
        "Developing comprehensive business continuity plans and procedures to ensure the timely and effective response and recovery from disruptions. This includes defining roles and responsibilities, establishing communication protocols, and outlining recovery strategies for critical business functions and IT systems.",
    },
    {
      title: "Crisis Management and Incident Response",
      description:
        "Establishing protocols and procedures for crisis management and incident response to effectively coordinate response efforts and mitigate the impact of disruptions. Conducting tabletop exercises and simulations to test response plans and improve readiness.",
    },
  ];

  const pElements = [
    "We provide a wide range of services to help businesses develop, implement, and maintain robust continuity plans and strategies.",
    "Our team of experienced BCMS professionals offers comprehensive solutions tailored to your organization's unique needs and objectives.",
    "Get in touch with us today to learn more about our BCMS services and how we can help your business achieve resilience and continuity.",
  ];

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan(
            "Business Continuity Management System (BCMS) Services"
          )}
        </h1>
        <p
          id={`${styles.sameP}`}
          ref={(el) => (elementsRef.current[1] = el)}
          className={`${styles.fadein} samePp`}
        >
          {wrapCharactersInSpan(
            "At Finnovator IT Solutions, we understand the critical importance of business continuity in ensuring the resilience and continuity of your organization's operations, especially in the face of unforeseen disruptions and disasters. Our Business Continuity Management System (BCMS) services are designed to help businesses develop, implement, and maintain robust continuity plans and strategies to mitigate risks and minimize downtime. With our team of experienced BCMS professionals, we offer a comprehensive suite of services to address your organization's business continuity needs and challenges."
          )}
        </p>
        <h3
          id={`${styles.gh}`}
          ref={(el) => (elementsRef.current[2] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Our Services Includes")}
        </h3>
        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            {serviceContent.map((service, index) => (
              <React.Fragment key={index}>
                <h1
                  id={`${styles.fHh}`}
                  ref={(el) => (elementsRef.current[index + 3] = el)}
                  className={`${styles.fadein} sameHh`}
                >
                  {wrapCharactersInSpan(service.title)}
                </h1>
                <p
                  ref={(el) => (elementsRef.current[index + 6] = el)}
                  className={`${styles.fadein} `}
                >
                  {wrapCharactersInSpan(service.description)}
                </p>
              </React.Fragment>
            ))}
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img src={three} alt="" />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img src={one} alt="" />
          </div>
          <div className={`${styles.left_pic_items}`}>
            {pElements.map((pText, index) => (
              <p
                key={index}
                ref={(el) => (elementsRef.current[index + 9] = el)}
                className={`${styles.fadein} `}
              >
                {wrapCharactersInSpan(pText)}
              </p>
            ))}
          </div>
        </div>
        <button
          ref={(el) => (elementsRef.current[12] = el)}
          className={`${styles.fadein} sameB`}
        >
          {wrapCharactersInSpan("Get in touch")}
        </button>
        <OurProcess />
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
