import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import one from "../../../assets/images/loan management/3d-cash-money (1).webp";
import two from "../../../assets/images/loan management/3d-cash-money.1.webp";
import three from "../../../assets/New Pics/IPA-header.jpg";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import SolutionsPage from "../../../components/HomePage/Gallery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function LoanManagement() {
  const scrollRef = useRef(null);

  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);
  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={three}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein}`}
        >
          {wrapCharactersInSpan("Loan Management & Servicing")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            {[
              "Payment posting (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
              "Payment Reversal (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
              "Payment Refund (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
              "Bulk Payment Posting (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
              "Bulk Payment Reversal (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
              "Bulk Refund (Against Financing Receivables AND Against Other Charges OR Non-Financing Receivables)",
            ].map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 1] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[7] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[8] = el)}
              className={`${styles.fadein}`}
              src={two}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            {[
              "Charges Creation",
              "Bulk Charges Upload",
              "Customer Payable Creation",
              "Bulk Customer Payable Upload",
              "Early Termination",
              "Foreclosure",
              "Loan Reschedule",
              "Partial Pre-Payment Reschedule",
              "Restructuring",
              "Refinancing",
              "Installment Date Deferment (Bulk / Individual)",
            ].map((text, index) => (
              <p
                ref={(el) => (elementsRef.current[index + 9] = el)}
                className={`${styles.fadein}`}
                key={index}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[20] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
