import React from "react";
import "./Appp.css";

const Decoration = () => (
  <>
    <div className="purple-circle" />
    <div className="gradient-circle-bottom" />
    <div className="green-circle" />
    <div className="yellow-circle" />
    <div className="gradient-circle" />
    <div className="dashed-circle">
      {Array.from({ length: 15 }).map((_, index) => (
        <div key={index} className="line" />
      ))}
    </div>
    <div className="little-green-circle" />
  </>
);

export default Decoration;
