import React, { useEffect, useRef } from "react";
import styles from "./weAreCompany.module.css";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`${styles.WeArCom}`}>
        <div className={`${styles.left_c}`}>
          <h3
            ref={(el) => (elementsRef.current[0] = el)}
            className={`${styles.fadein} sameH`}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
            recusandae facilis quas magni ad deserunt commodi sequi consectetur,
            fuga vitae.
          </h3>
        </div>
      </div>
    </div>
  );
}
