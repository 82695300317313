import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import gsap from "gsap";
import one from "../../../assets/images/cyber-security/cyber-hero.webp";
import two from "../../../assets/images/cyber-security/futurism-perspective-digital-nomads-lifestyle.webp";
import three from "../../../assets/images/cyber-security/glowing-abstract-computer-network-symbol-illuminates-dark-night-generated-by-ai.webp";
import four from "../../../assets/images/cyber-security/security-system-locks-data-computer-safety-generated-by-ai.webp";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import OurProcess from "../../../components/HomePage/OurProcess";
import SolutionsPage from "../../../components/HomePage/Gallery";

gsap.registerPlugin(ScrollTrigger);

export default function CyberSecurity() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top", // Start animation when image enters the center of the viewport
        end: "bottom center", // End animation when image leaves the center of the viewport
        scrub: true, // Smoothly animates the opacity based on scroll position
      },
    });
  }, []);

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[1] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Cybersecurity Consultancy Services")}
        </h1>
        <p
          id={`${styles.sameP}`}
          ref={(el) => (elementsRef.current[2] = el)}
          className={`${styles.fadein} samePp`}
        >
          {wrapCharactersInSpan(
            "At Finnovator IT Solutions, we understand the critical importance of cybersecurity in safeguarding your organization's digital assets and data. Our cybersecurity consultancy services are designed to help businesses mitigate cyber risks, strengthen their security posture, and achieve compliance with industry regulations. With our team of experienced cybersecurity experts, we offer a comprehensive range of consultancy services tailored to meet your organization's specific needs and challenges."
          )}
        </p>
        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[3] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Security Assessment and Gap Analysis")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[4] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Assessing your organization's cybersecurity posture, identifying vulnerabilities and gaps, and providing recommendations for improvement."
              )}
            </p>
            <h1
              ref={(el) => (elementsRef.current[5] = el)}
              className={`${styles.fadein} sameHh`}
              id={`${styles.fHh}`}
            >
              {wrapCharactersInSpan(
                "Security Governance and Policy Development"
              )}
            </h1>
            <p
              ref={(el) => (elementsRef.current[6] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Establishing robust cybersecurity governance frameworks, policies, and procedures to ensure accountability and oversight of cybersecurity initiatives."
              )}
            </p>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[7] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Cyber Risk Management")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[8] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Developing customized risk management strategies to prioritize and mitigate cyber threats based on your organization's risk tolerance and business objectives."
              )}
            </p>
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[9] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
        </div>
        <div className={`${styles.left_pic}`}>
          <div className={`${styles.left_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[101] = el)}
              className={`${styles.fadein}`}
              src={three}
              alt=""
            />
          </div>
          <div className={`${styles.left_pic_items}`}>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[11] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Third-Party Risk Management")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[12] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Assessing and managing cybersecurity risks associated with third-party vendors and partners to mitigate supply chain risks."
              )}
            </p>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[13] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Security Architecture and Design")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[14] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Designing and implementing secure IT infrastructures, networks, and systems to protect against cyber threats and ensure compliance with best practices."
              )}
            </p>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[15] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan(
                "Incident Response Planning and Preparedness"
              )}
            </h1>
            <p
              ref={(el) => (elementsRef.current[16] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Developing incident response plans and procedures to effectively detect, respond to, and recover from cybersecurity incidents."
              )}
            </p>
          </div>
        </div>
        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[17] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan(
                "Cybersecurity Technology Evaluation and Integration"
              )}
            </h1>
            <p
              ref={(el) => (elementsRef.current[18] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Evaluating and integrating cybersecurity technologies and solutions to meet your organization's security requirements and objectives."
              )}
            </p>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[19] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Security Awareness and Training")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[20] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Providing cybersecurity awareness and training programs to educate your employees about cyber risks and best practices for mitigating them."
              )}
            </p>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[21] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Regulatory Compliance")}
            </h1>
            <p
              ref={(el) => (elementsRef.current[22] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "Ensuring your organization's compliance with industry regulations, data protection laws, and cybersecurity standards."
              )}
            </p>
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[10] = el)}
              className={`${styles.fadein}`}
              src={four}
              alt=""
            />
          </div>
        </div>
        <h1
          ref={(el) => (elementsRef.current[23] = el)}
          className={`${styles.fadein} sameHh`}
        >
          {wrapCharactersInSpan(
            "Why Choose Finnovator IT Solutions for Cybersecurity Consultancy?"
          )}
        </h1>
        <p
          ref={(el) => (elementsRef.current[24] = el)}
          className={`${styles.fadein} sameP`}
        >
          {wrapCharactersInSpan(
            "Expertise: Our team of cybersecurity experts brings years of experience and industry knowledge to every project."
          )}
        </p>
        <p
          ref={(el) => (elementsRef.current[25] = el)}
          className={`${styles.fadein} sameP`}
        >
          {wrapCharactersInSpan(
            "Customized Solutions: We understand that every organization is unique, and we tailor our consultancy services to meet your specific needs and objectives."
          )}
        </p>
        <p
          ref={(el) => (elementsRef.current[26] = el)}
          className={`${styles.fadein} sameP`}
        >
          {wrapCharactersInSpan(
            "Proven Track Record: We have a proven track record of helping businesses across various industries improve their cybersecurity posture and resilience."
          )}
        </p>
        <button
          ref={(el) => (elementsRef.current[27] = el)}
          className={`${styles.fadein} sameB`}
        >
          {wrapCharactersInSpan("Get in touch")}
        </button>
        <OurProcess />
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
