import React, { useEffect, useRef } from "react";
import styles from "./expertese.module.css";
import listimage from "../../../assets/images/list-icn-n.svg";
import { NavLink } from "react-router-dom";
import WhatsAppButton from "../../Utility/WhatsAppButton";
export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const industries = [
    {
      title: "Business Solutions",
      description:
        "Drive success with our end-to-end business solutions, integrating technology, strategy, and creativity for optimal results.",
    },
    {
      title: "Sharia Compliant Financing",
      description:
        "- Sharia-compliant financing solutions and services tailored to align with your values and financial needs.",
    },
    {
      title: "Web Development",
      description:
        "Utilize cutting-edge technologies for seamless, visually appealing, and high-performance websites tailored to your needs.",
    },
    {
      title: "Digitalization",
      description:
        "Transforming businesses through comprehensive digitalization, driving efficiency and innovation.",
    },
 
  ];

  return (
    <div className={`${styles.Company}`}>
      <h1
        className={`${styles.title} ${styles.fadein} sameH`}
        ref={(el) => (elementsRef.current[0] = el)}
      >
        Expertise
      </h1>
      <div className={`${styles.grid}`}>
        {industries.map((industry, index) => (
          <div
            key={index}
            className={`${styles.card} ${styles.fadein}`}
            ref={(el) => (elementsRef.current[index + 1] = el)}
          >
            <div className={` ${styles.cardContent} sameP`}>
              <img src={listimage} alt="" className={`${styles.icon}`} />
              <div>
                <h3 className={`${styles.cardTitle} sameI`}>
                  {industry.title}
                </h3>
                <p className={`${styles.description}  sameD`}>
                  {industry.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.button} `}>
        <button className={`${styles.contactButton} sameB`}>
          <NavLink to="/contact">Contact Us</NavLink>
        </button>
        <WhatsAppButton whatsappNumber="+971564452061" />
      </div>
    </div>
  );
}
// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
