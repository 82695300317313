import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  return (
    <div
      ref={(el) => (elementsRef.current[0] = el)}
      className={`${styles.pre_f} ${styles.fadein}`}
    >
      <hr />
      <div className={`${styles.Footer}`}>
        <ul className={`${styles.ist_ul}`}>
          <li className={`${styles.one}`}>
            <div className={`${styles.one_flex}`}>
              <div className={`${styles.one_item}`}>
                <div className={`${styles.one_items}`}>
                  <h3>UAE- Dubai</h3>
                  <p>
                    Dubai Silicon Oasis <br />
                    DDP <br />
                    Building A1 <br />
                    Dubai, United Arab Emirates
                  </p>
                </div>
                <div className={`${styles.one_items}`}>
                  <p>
                    <a className="call-button" href="tel:+971564452061">
                      +971 56 445 2061
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li className={`${styles.two}`}>
            <ul>
              <li>
                <NavLink to="/digital-financing">Digital Financing</NavLink>
              </li>
              <li>
                <NavLink to="/loan-origination">Loan Origination</NavLink>
              </li>
              <li>
                <NavLink to="/loan-management">Loan Management</NavLink>
              </li>
            </ul>
          </li>
          <li className={`${styles.three}`}>
            <ul>
              <li>
                <NavLink to="/loan-collection">Loan Collection</NavLink>
              </li>
              <li>
                <NavLink to="/Leased-Inventory-Management">
                  Leased Inventory Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/information-technology">
                  Information Technology
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={`${styles.four}`}>
            <ul>
              <li>
                <NavLink to="/cyber-security">Cyber Security</NavLink>
              </li>
              <li>
                <NavLink to="/risk-governance">Risk & Governance</NavLink>
              </li>
              <li>
                <NavLink to="/busines-continuity">Business Continuity</NavLink>
              </li>
            </ul>
          </li>
        </ul>
       
      </div>
      <hr />
      
      <p className={`${styles.span} `}>
        2024 Finnavator IT Solutions. All Rights Reserved
      </p>
    </div>
  );
}

// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
