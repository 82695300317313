import React, { useEffect, useState, useRef } from "react";
import styles from "./career.module.css";
import backgroundImg from "../../../assets/images/cloud.webp";
import image1 from "../../../assets/images/imgpsh_fullsize_anim1.png";
import image2 from "../../../assets/images/ti.webp";
import image3 from "../../../assets/images/imgpsh_fullsize_animn.png";
import image4 from "../../../assets/images/man.webp";
import image5 from "../../../assets/images/slide-1-anim.png";
import image6 from "../../../assets/images/CARRER-removebg-preview.png";
import image7 from "../../../assets/images/carrer.png";

export default function Index() {
  const [opacity, setOpacity] = useState(1);
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const image7Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newOpacity = Math.max(1 - scrollY / 50, 0);
      setOpacity(newOpacity);

      const newOverlayOpacity = Math.min(scrollY / 50, 1);
      setOverlayOpacity(newOverlayOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.F}>
      <div
        className={styles.blackOverlay}
        style={{ opacity: overlayOpacity }}
      ></div>
      <div className={styles.banner}>
        <div className={styles.backgroundContainer} style={{ opacity }}>
          <img
            src={backgroundImg}
            className={styles.background}
            alt="Background"
          />
          <img
            src={image1}
            className={`${styles.image} ${styles.image1}`}
            alt="Image 1"
          />
          <img
            src={image7}
            className={`${styles.image} ${styles.image2}`}
            alt="Image 2"
          />
          <img
            src={image3}
            className={`${styles.image} ${styles.image3}`}
            alt="Image 3"
          />
          <img
            src={image6}
            className={`${styles.image} ${styles.image4} ${styles.slideDown}`}
            alt="Image 4"
          />
          <img src={image5} className={`${styles.image5}`} alt="Image 5" />
        </div>
        {/* <img
          ref={image7Ref}
          src={image7}
          className={`${styles.image7}`}
          alt="Image 7"
        /> */}
        <h3>CAREERS</h3>
      </div>
    </div>
  );
}
