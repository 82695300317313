import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import gsap from "gsap";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import one from "../../../assets/images/digital financing/computer-tablet-smartphone-table-lamp-dark-room-3d-rendering.webp";
import two from "../../../assets/images/digital financing/digital-financing-1.webp";
import three from "../../../assets/images/digital financing/digital-financing-2.webp";
import four from "../../../assets/images/digital financing/digital-financing-hero-image.webp";
import five from "../../../assets/images/digital financing/glowing-finger-controls-modern-nightlife-technology-table-generated-by-ai.webp";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import SolutionsPage from "../../../components/HomePage/Gallery";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function DigitalFinance() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const loanOriginationTexts = [
    "Apply for Financing For Personal Loan, Vehicle, Goods, Services, BNPL etc.",
    "Recent Requests’ Status",
    "Registered Suppliers Listing",
    "Assets lists from Registered Supplier",
    "Item Scanning Option for Registered Suppliers",
    "Fully Integrated Vehicle Insurance and registration for Vehicle Leasing",
  ];

  const loanServicingTexts = [
    "Installment Payments using payment gateways",
    "Foreclosure Simulator/Request",
    "Reschedule Simulator/Request",
    "Defer Installment Request",
    "Customer Statement",
  ];

  const customerServiceTexts = [
    "Collection Reminders & Notifications",
    "Customer Service",
    "Service Requests",
    "Complaints",
    "Inquiries",
    "Online Chatting & Direct Whatsapp link etc.",
  ];

  const otherOptionsTexts = [
    "Push Notifications",
    "Customer Personal Information",
    "Update IBAN",
    "Update Mobile number",
    "Update ID Expiry",
    "Language Selection",
    "Theme Selection",
    "Payment Methods & Saved Cards",
    "Delete Account",
  ];

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>

      <div className={styles.detail_main}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Mobile Application & Web Portal")}
        </h1>

        <h3
          id={styles.gh}
          ref={(el) => (elementsRef.current[1] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("Customer Registration")}
        </h3>
        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              id={styles.fHh}
              ref={(el) => (elementsRef.current[2] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Loan Origination")}
            </h1>

            {loanOriginationTexts.map((text, index) => (
              <p
                key={index}
                ref={(el) => (elementsRef.current[index + 3] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}

            <h1
              id={styles.fHh}
              ref={(el) => (elementsRef.current[9] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Loan Servicing")}
            </h1>

            {loanServicingTexts.map((text, index) => (
              <p
                key={index}
                ref={(el) => (elementsRef.current[index + 10] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[15] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt="Example 1"
            />
          </div>
        </div>
        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img src={three} alt="Example 2" />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[16] = el)}
              className={`${styles.fadein} sameHh`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Customer Service & Collection")}
            </h1>

            {customerServiceTexts.map((text, index) => (
              <p
                key={index}
                ref={(el) => (elementsRef.current[index + 17] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>
        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              id={styles.fHh}
              ref={(el) => (elementsRef.current[23] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan("Other Options")}
            </h1>

            {otherOptionsTexts.map((text, index) => (
              <p
                key={index}
                ref={(el) => (elementsRef.current[index + 24] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[33] = el)}
              className={`${styles.fadein}`}
              src={four}
              alt="Example 3"
            />
          </div>
        </div>
        <button
          ref={(el) => (elementsRef.current[34] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
