import React, { useEffect, useRef } from "react";
import styles from "../../pages/Deails/DetailPages.module.css";
import Navbar from "../../components/NavBar";
import Solutions from "../../components/HomePage/Solutions";
import Services from "../../components/HomePage/Services";
import Contact from "../../components/HomePage/Contact";
import Footer from "../../components/HomePage/Footer";
import two from "../../assets/images/leased inventory/calculating-finances-investing-future-wealth-generated-by-ai.webp";
import one from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import three from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import four from "../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import gsap from "gsap";
import SolutionsPage from "../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
import style2 from "./styles.module.css";
import Image1 from "../../assets/MobileApp/f.png";
import Image2 from "../../assets/MobileApp/Capture20.PNG";
import Image3 from "../../assets/MobileApp/3.png";
import Image4 from "../../assets/MobileApp/4.png";
gsap.registerPlugin(ScrollTrigger);

export default function MobileApplication() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag && tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else if (tag) {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [""];

  const leftPicTexts = [""];

  return (
    <div id="scroll-container" className={`${styles.mj} ${style2.mj}`}>
      <Navbar />
      <div className={styles.top_banner}>
        
        <h1 className={`sameH`}>
          {wrapCharactersInSpan("Alif Lending Mobile Application")}
        </h1>
      </div>
      <div className={`${styles.detail_main}`}>
        <div className={`${style2.first} ${styles.right_pic}`}>
          <div className={`${style2.fContent}`}>
            <ul>
              <li ref={(el) => (elementsRef.current[12] = el)}>
                {wrapCharactersInSpan("Home page")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[13] = el)}>
                {wrapCharactersInSpan("Products & Services ")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[14] = el)}>
                {wrapCharactersInSpan("Advertisement ")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[15] = el)}>
                {wrapCharactersInSpan("Offers ")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[16] = el)}>
                {wrapCharactersInSpan("News & Events ")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[17] = el)}>
                {wrapCharactersInSpan(
                  "Apply for Financing For Personal Loan, Vehicle, Goods, Services, BNPL etc. "
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[18] = el)}>
                {wrapCharactersInSpan("Recent Requests’ Status ")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[19] = el)}>
                {wrapCharactersInSpan("Registered Suppliers Listing")}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[30] = el)}>
                {wrapCharactersInSpan("Assets lists from Registered Supplier ")}
              </li>
              <li ref={(el) => (elementsRef.current[31] = el)}>
                {wrapCharactersInSpan(
                  "Item Scanning Option for Registered Suppliers "
                )}
              </li>
              <li ref={(el) => (elementsRef.current[32] = el)}>
                {wrapCharactersInSpan(
                  "Insurance Quotation and Tamm registration for Ijarah"
                )}
              </li>
              <li ref={(el) => (elementsRef.current[33] = el)}>
                {wrapCharactersInSpan(
                  "Installment Payments using payment gateways"
                )}
              </li>
              <li ref={(el) => (elementsRef.current[34] = el)}>
                {wrapCharactersInSpan("Foreclosure Simulator/Request")}
              </li>
              <li ref={(el) => (elementsRef.current[35] = el)}>
                {wrapCharactersInSpan("Reschedule Simulator/Request")}
              </li>
              <li ref={(el) => (elementsRef.current[36] = el)}>
                {wrapCharactersInSpan("Defer Installment Request ")}
              </li>
              <li ref={(el) => (elementsRef.current[37] = el)}>
                {wrapCharactersInSpan("Customer Statement")}
              </li>
            </ul>
            <h2 ref={(el) => (elementsRef.current[38] = el)}>
              {wrapCharactersInSpan("LOAN COLLECTION")}
            </h2>
            <ul>
              <li ref={(el) => (elementsRef.current[39] = el)}>
                {wrapCharactersInSpan(
                  "Push Notifications for all collection related matters"
                )}
              </li>
            </ul>
          </div>
          <div className={`${style2.fImage}`}>
            <img src={Image1} alt="" />
          </div>
        </div>

        <div className={`${style2.first} ${styles.right_pic}`}>
          <div className={`${style2.fImage}`}>
            <img src={Image3} alt="" />
          </div>
          <div className={`${style2.fContent}`}>
            <h2 ref={(el) => (elementsRef.current[40] = el)}>
              {wrapCharactersInSpan("CUSTOMER SERVICE")}
            </h2>
            <ul>
              <li ref={(el) => (elementsRef.current[41] = el)}>
                {wrapCharactersInSpan("Service Requests ")}
              </li>
              <li ref={(el) => (elementsRef.current[42] = el)}>
                {wrapCharactersInSpan("Complaints")}
              </li>
              <li ref={(el) => (elementsRef.current[43] = el)}>
                {wrapCharactersInSpan("Inquiries ")}
              </li>
              <li ref={(el) => (elementsRef.current[44] = el)}>
                {wrapCharactersInSpan(
                  "Online Chatting & Direct Whatsapp link etc"
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className={`${style2.first} ${style2.first2} ${styles.right_pic}`}>
          <div className={`${style2.fContent}`}>
            <h2 ref={(el) => (elementsRef.current[45] = el)}>
              {wrapCharactersInSpan("OTHER OPTIONS")}
            </h2>
            <ul>
              <li ref={(el) => (elementsRef.current[46] = el)}>
                {wrapCharactersInSpan("Push Notifications ")}
              </li>
              <li ref={(el) => (elementsRef.current[47] = el)}>
                {wrapCharactersInSpan("Customer Personal Information")}
              </li>
              <li ref={(el) => (elementsRef.current[48] = el)}>
                {wrapCharactersInSpan("Update IBAN ")}
              </li>
              <li ref={(el) => (elementsRef.current[49] = el)}>
                {wrapCharactersInSpan("Update Mobile number ")}
              </li>
              <li ref={(el) => (elementsRef.current[52] = el)}>
                {wrapCharactersInSpan("Update ID Expiry")}
              </li>
              <li ref={(el) => (elementsRef.current[53] = el)}>
                {wrapCharactersInSpan("Language Selection ")}
              </li>
              <li ref={(el) => (elementsRef.current[54] = el)}>
                {wrapCharactersInSpan("Theme Selection ")}
              </li>
              <li ref={(el) => (elementsRef.current[55] = el)}>
                {wrapCharactersInSpan("Payment Methods & Saved Cards ")}
              </li>
              <li ref={(el) => (elementsRef.current[56] = el)}>
                {wrapCharactersInSpan("Delete Account ")}
              </li>
            </ul>
          </div>
          <div className={`${style2.fImage}`}>
            {" "}
            <img src={Image4} alt="" />
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[97] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
