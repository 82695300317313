import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import one from "../../../assets/images/leased inventory/calculating-finances-investing-future-wealth-generated-by-ai.webp";
import two from "../../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import three from "../../../assets/images/leased inventory/leased-hero-section.webp";
import four from "../../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import five from "../../../assets/images/loan-collection/hand-holding-coin-bank-symbolizing-wealth-growth-generated-by-ai.webp";
import six from "../../../assets/images/R&G01/photorealistic-hands-holding-planet-earth.webp";
import seven from "../../../assets/images/R&G01/freepik-export-20240603135030Hkdr.webp";
import eight from "../../../assets/images/business continuity/scene-with-business-person-working-futuristic-office-job.webp";
import nine from "../../../assets/images/cyber-security/futurism-perspective-digital-nomads-lifestyle.webp";
import gsap from "gsap";
import SolutionsPage from "../../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
gsap.registerPlugin(ScrollTrigger);

export default function AML_CF() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [
    "Customer Onboarding: Facilitates the collection and verification of customer information during the onboarding process. ",
    "Know Your Customer (KYC): Verifies and validates customer identities and assesses the risk associated with each customer. ",
  ];

  const leftPicTexts = [
    "Entity Matching: Compares customer and transaction data against national and international sanction lists. ",
    "Automated Screening: Conducts real-time screening of customers and transactions against updated sanction lists. ",
    "Transaction Monitoring: Anomaly Detection: Identifies unusual patterns or behaviors in transactional data. ",
    "Threshold Monitoring: Sets thresholds for transactions or activities triggering alerts for further investigation.",
  ];
  const rightPicTexts1 = [
    "Customer Risk Profiling: Assigns risk levels to customers based on their profile, behavior, and transaction history.",
    "Transaction Risk Assessment: Evaluates the risk associated with individual transactions or patterns of transactions.",
  ];
  const leftPicTexts1 = [
    "Alert Generation: Generates alerts for potentially suspicious activities or high-risk entities.",
    "Alert Escalation: Escalates alerts to upper levels. ",
  ];
  const rightPicTexts2 = [
    "Workflow Automation: Automates routine processes and workflows.",
    "Rules Engine: Implements customizable rules for risk assessment and alert generation.",
  ];
  const leftPicTexts2 = [
    "Investigation Workflow: Facilitates the process of investigating and documenting suspicious activities. ",
    "Recordkeeping: Maintains a record of investigations, actions taken, and outcomes.",
  ];
  const rightPicTexts3 = [
    "Regulatory Reporting: Generates reports required for regulatory compliance.",
    "Audit Trails: Maintains comprehensive audit trails for all system activities.",
  ];
  const leftPicTexts3 = [
    "Compliance Checks: Ensures adherence to regulatory compliance for AML and CFT regulations and guidelines.",
    "Policy Enforcement: Provides features to implements and enforces AML policies and procedures in the system to monitor the integrated systems.",
  ];
  const rightPicTexts4 = [
    "Integration with Watchlists: Connects with external providers to access and update sanction lists and watchlists.",
    "Data Feeds: Integrates with third-party data feeds for enhanced risk intelligence.",
  ];
  const leftPicTexts4 = [
    "Suspicious Activity Report (SAR) Filing: Prepares and provides SARs as required by regulatory authorities.",
    "Documentation: Ensures proper recording of the resolution process for each alert.",
  ];
  const rightPicTexts5 = [
    "User Authentication: Provides secure authentication methods for user access.",
    "Access Control: Enforces access controls, based on user roles and responsibilities.",
    "Access Logs: Maintains detailed logs of user activities for audit and compliance purposes. ",
  ];

  let refIndex = 0;

  return (
    <div id="scroll-container">
      <Navbar />

      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={eight}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={styles.detail_main}>
        <h1
          ref={(el) => (elementsRef.current[refIndex++] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("AML & CF")}
        </h1>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Customer Due Diligence")}
            </h1>

            {rightPicTexts.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={nine}
              alt=""
            />
          </div>
        </div>

        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={five}
              alt=""
            />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Sanction List Screening")}
            </h1>

            {leftPicTexts.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Risk Assessment")}
            </h1>

            {rightPicTexts1.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={five}
              alt=""
            />
          </div>
        </div>

        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={three}
              alt=""
            />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Alert Generation and Management")}
            </h1>

            {leftPicTexts1.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Automated Workflow and Rules Engine")}
            </h1>

            {rightPicTexts2.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={six}
              alt=""
            />
          </div>
        </div>

        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={seven}
              alt=""
            />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Case Management")}
            </h1>

            {leftPicTexts2.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Reporting and Logging")}
            </h1>

            {rightPicTexts3.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={two}
              alt=""
            />
          </div>
        </div>

        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={two}
              alt=""
            />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Regulatory Compliance")}
            </h1>

            {leftPicTexts3.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Integration with External Data Sources")}
            </h1>

            {rightPicTexts4.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={seven}
              alt=""
            />
          </div>
        </div>

        <div className={styles.left_pic}>
          <div className={styles.left_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
          <div className={styles.left_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("Alert Resolution and SAR Filing")}
            </h1>

            {leftPicTexts4.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.right_pic}>
          <div className={styles.right_pic_items}>
            <h1
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              id={styles.fHh}
            >
              {wrapCharactersInSpan("User Authentication and Access Logs")}
            </h1>

            {rightPicTexts5.map((text) => (
              <p
                ref={(el) => (elementsRef.current[refIndex++] = el)}
                className={`${styles.fadein}`}
                key={refIndex}
              >
                {wrapCharactersInSpan(text)}
              </p>
            ))}
          </div>
          <div className={styles.right_pic_items}>
            <img
              ref={(el) => (elementsRef.current[refIndex++] = el)}
              className={`${styles.fadein}`}
              src={nine}
              alt=""
            />
          </div>
        </div>
        <button
          ref={(el) => (elementsRef.current[20] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
