import React, { useEffect, useRef } from "react";
import styles from "../../pages/Deails/DetailPages.module.css";
import Navbar from "../../components/NavBar";
import Solutions from "../../components/HomePage/Solutions";
import Services from "../../components/HomePage/Services";
import Contact from "../../components/HomePage/Contact";
import Footer from "../../components/HomePage/Footer";
import two from "../../assets/flordia/logo.png";
import one from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import three from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import four from "../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import gsap from "gsap";
import SolutionsPage from "../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
import style2 from "./styles.module.css";
import Image1 from "../../assets/flordia/2.png";
import Image2 from "../../assets/flordia/1.png";
import Image3 from "../../assets/flordia/3.png";
import Image4 from "../../assets/flordia/4.png";
import video from "../../assets/FlordiaVideo.mp4";
gsap.registerPlugin(ScrollTrigger);

export default function Flordia() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag && tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else if (tag) {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [""];

  const leftPicTexts = [""];

  return (
    <div id="scroll-container" className={`${style2.flordia} ${styles.mj} ${style2.mj}`}>
      <Navbar />
      <div className={`${styles.top_banner} ${style2.bann}`}>
        
        <h1 className={` sameH`}>
          {wrapCharactersInSpan("Website Development for Florida Real Estate Specialist")}
        </h1>
      </div>
      <div className={`${styles.detail_main}`}>
        <div className={`${style2.first} ${styles.right_pic}`}>
          <div className={`${style2.fContent}`}>
            <h2
              ref={(el) => (elementsRef.current[0] = el)}
              
            >
              {wrapCharactersInSpan("Background")}
            </h2>
            <ul>
              <li ref={(el) => (elementsRef.current[1] = el)}>
                {wrapCharactersInSpan(
                  `The Florida Real Estate Specialist is a leading real estate agency based in Miami Beach, Florida. With a focus on providing expert advice and premier property services, the agency recognized the need for a robust online presence to attract and serve its growing client base. `
                )}{" "}
              </li>
            </ul>
            <h2
              ref={(el) => (elementsRef.current[2] = el)}
             
            >
              {wrapCharactersInSpan("Objectives")}
            </h2>
            <p ref={(el) => (elementsRef.current[4] = el)}>
              The primary objectives of developing the new website were:
            </p>
            <ul>
              <li ref={(el) => (elementsRef.current[5] = el)}>
                <strong>User-Friendly Navigation:</strong>
                {wrapCharactersInSpan(
                  ` Simplify the property search and management processes for potential clients.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[6] = el)}>
                <strong>Showcase Property Listings:</strong>{" "}
                {wrapCharactersInSpan(
                  ` Highlight luxury properties in South Florida effectively.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[7] = el)}>
                <strong>Lead Generation:</strong>{" "}
                {wrapCharactersInSpan(
                  ` Enhance the site's capability to generate inquiries and maintain client relationships.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[8] = el)}>
                <strong>Mobile Optimization:</strong>{" "}
                {wrapCharactersInSpan(
                  ` Ensure that the website is mobile-friendly to cater to users on various devices.`
                )}{" "}
              </li>
            </ul>
          </div>
          <div className={`${style2.fImage}`}>
            <img src={Image1} alt="" />
          </div>
        </div>
        <div className={`${style2.first} ${style2.first2} ${styles.right_pic}`}>
          <div className={`${style2.fImage}`}>
            {" "}
            <img src={Image2} alt="" />
          </div>

          <div className={`${style2.fContent}`}>
            <h2
              ref={(el) => (elementsRef.current[99] = el)}
            
            >
              {wrapCharactersInSpan("Development Process")}
            </h2>
            {/* <p ref={(el) => (elementsRef.current[4] = el)}>
              The primary objectives of developing the new website were:
            </p> */}

            <p ref={(el) => (elementsRef.current[10] = el)}>
              {" "}
              <strong>Research and Planning</strong>
            </p>
            <ul>
              <li ref={(el) => (elementsRef.current[10] = el)}>
                {wrapCharactersInSpan(
                  `Engaged with stakeholders to understand business goals and client expectations.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                {wrapCharactersInSpan(
                  ` Analyzed competitor websites for insights on industry standards and best practices.`
                )}{" "}
              </li>
            </ul>
            <p ref={(el) => (elementsRef.current[12] = el)}>
              {" "}
              <strong>Design</strong>
            </p>

            <ul>
              <li ref={(el) => (elementsRef.current[13] = el)}>
                {wrapCharactersInSpan(
                  `Crafted a visually appealing layout that reflects the luxury and exclusivity of South Florida real estate. `
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[14] = el)}>
                {wrapCharactersInSpan(
                  `Developed a branding strategy that aligns with the agency's identity.`
                )}{" "}
              </li>
            </ul>

            <p ref={(el) => (elementsRef.current[15] = el)}>
              {" "}
              <strong>Content Creation</strong>
            </p>

            <ul>
              <li ref={(el) => (elementsRef.current[16] = el)}>
                {wrapCharactersInSpan(
                  `Created compelling and relevant content for property listings, including descriptions, images, and videos`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[7] = el)}>
                {wrapCharactersInSpan(
                  `Implemented SEO best practices to enhance visibility in search engines.`
                )}{" "}
              </li>
            </ul>

            <p ref={(el) => (elementsRef.current[18] = el)}>
              {" "}
              <strong>Technology Implementation</strong>
            </p>

            <ul>
              <li ref={(el) => (elementsRef.current[19] = el)}>
                {wrapCharactersInSpan(
                  `Utilized a responsive web design framework to ensure mobile optimization.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[20] = el)}>
                {wrapCharactersInSpan(
                  ` Integrated a property search functionality and contact forms to facilitate inquiries.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[21] = el)}>
                {wrapCharactersInSpan(
                  `  Implemented a property management backend interface for seamless updates by real estate agents.`
                )}{" "}
              </li>
            </ul>

            <p ref={(el) => (elementsRef.current[22] = el)}>
              {" "}
              <strong>Testing and Launch</strong>
            </p>

            <ul>
              <li ref={(el) => (elementsRef.current[23] = el)}>
                {wrapCharactersInSpan(
                  `Conducted a thorough testing phase, focusing on user experience, functionality, and load times.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[24] = el)}>
                {wrapCharactersInSpan(
                  `Launched the website after final adjustments based on feedback.`
                )}{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className={`${style2.first} ${styles.right_pic}`}>
          <div className={`${style2.fContent}`}>
            <h2
              ref={(el) => (elementsRef.current[900] = el)}
              
            >
              {wrapCharactersInSpan("Key Features")}
            </h2>

            <ul>
              <li ref={(el) => (elementsRef.current[10] = el)}>
                <strong>Property Search</strong>
                {wrapCharactersInSpan(
                  `An intuitive search feature that allows users to filter properties based on various criteria.
`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Active Listings</strong>
                {wrapCharactersInSpan(
                  ` Real-time updates on available properties`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Client Testimonials</strong>
                {wrapCharactersInSpan(
                  ` Highlighting positive experiences to build trust with potential buyers and sellers.
`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Blog and Resources</strong>
                {wrapCharactersInSpan(
                  ` Educational content that provides insights and guides on the real estate market.
.
`
                )}{" "}
              </li>
            </ul>
            <h2
              ref={(el) => (elementsRef.current[9] = el)}
             
            >
              {wrapCharactersInSpan("Results")}
            </h2>

            <ul>
              <li ref={(el) => (elementsRef.current[10] = el)}>
                <strong>Increased Traffic</strong>
                {wrapCharactersInSpan(
                  `The website saw a 40% increase in monthly visitors within the first three months post-launch.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Higher Engagement</strong>
                {wrapCharactersInSpan(
                  `  Average session duration improved, indicating users were finding the content engaging and informative.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Lead Generation</strong>
                {wrapCharactersInSpan(
                  `The incorporation of forms and calls-to-action led to a 30% rise in inquiries.`
                )}{" "}
              </li>
              <li ref={(el) => (elementsRef.current[11] = el)}>
                <strong>Client Satisfaction</strong>
                {wrapCharactersInSpan(
                  ` Positive feedback from clients regarding ease of use and information availability.`
                )}{" "}
              </li>
            </ul>
          </div>
          <div className={`${style2.fImage}`}>
            <img src={Image3} alt="" />
          </div>
        </div>
        <div className={`${style2.first} ${style2.first2} ${styles.right_pic}`}>
          <div className={`${style2.fImage}`}>
            {" "}
            <img src={Image4} alt="" />
          </div>
          <div className={`${style2.fContent}`}>
            <h2
              ref={(el) => (elementsRef.current[45] = el)}
            
            >
              {wrapCharactersInSpan("Conclusion")}
            </h2>
            <ul>
              <li ref={(el) => (elementsRef.current[46] = el)}>
                {wrapCharactersInSpan("The website development for Florida Real Estate Specialist successfully met the business's objectives, significantly enhancing its online presence and customer engagement. The agency is now better positioned to serve its clients and showcase its portfolio of luxury properties in South Florida.")}
              </li>
              
            </ul>
          </div>
        </div>
        <div className={style2.videoDiv}>
          <video className={style2.video} autoPlay muted loop playsInline>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button
          ref={(el) => (elementsRef.current[97] = el)}
          className={` sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
