import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SocialIcons from './socialicnons1'; // Adjust the path as necessary

import logo from "../../assets/images/Logo-Small-F.png";
import logo1 from "../../assets/images/F Logo..png";
import styles from "./styles.module.css";
import { Link } from "react-scroll";
import { FaPhone, FaWhatsapp, FaEnvelope } from "react-icons/fa";

const Index = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showPopover, setShowPopover] = useState(false); // State for the popup
  const [showContactLinks, setShowContactLinks] = useState(false);

  const handleShowNavbar = () => {
    if (showNavbar) {
      // If the navbar is open and is being closed, hide the popover as well
      setShowPopover(false);
    }
    setShowNavbar(prevState => !prevState); // Toggles navbar visibility
  };
  const handleShowPopover = (e) => {
    e.preventDefault(); // Prevents default behavior for the link
    if (!showNavbar) {
      // Popover should only show when navbar is open
      return;
    }
    setShowPopover(prevState => !prevState); // Toggles popover visibility
  };


  const handleClosePopover = () => {
    setShowPopover(false); // Close the popup
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleShowContactLinks = () => {
    setShowContactLinks(prevState => !prevState); // Toggle visibility of contact links
  };
  
  return (

    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ""}`}>

      <div className={`${styles.container}`}>

        <div className={`${styles.logo} ${scrolled ? styles.smallLogo : ""}`}>
          <NavLink to="/">
            <Logo scrolled={scrolled} />
          </NavLink>
        </div>
        <div className={`${styles.menu_icon}`} onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`${styles.navElements} ${showNavbar ? styles.active : ""}`}>
          <ul>
            <li>
              <Link onClick={handleShowNavbar} to="About-Section" smooth={true} duration={500}>
                Why Finnovator
              </Link>
            </li>
            <li>
              <Link onClick={handleShowPopover} to="#" smooth={true} duration={500}>
                What We Are Offering
              </Link>
              {/* Popover for the "What We are offering" menu item */}
              {showPopover && (
                <Popover onClose={handleClosePopover} />
              )}
            </li>
            <li>
              <Link onClick={handleShowNavbar} to="Solutions-Section" smooth={true} duration={500}>
                Our Solutions
              </Link>
            </li>
            <li>
              <Link onClick={handleShowContactLinks} to="#">
                Contact Us
              </Link>
              {/* Render additional headings based on state */}
              {showContactLinks && (
                <div>
                  <h1><NavLink to="/Contact">·  Contact </NavLink></h1>
                  <h1><NavLink to="/Careers">· Career</NavLink></h1>
                </div>
              )}
            </li>
            
          </ul>
          
          <div className={`${styles.menu_icon} ${styles.X}`} onClick={handleShowNavbar}>
            <CloseIcon />
          </div>
        </div>
      </div>

      {/* Addresses Section */}
      {showNavbar && (
        <div className={`${styles.addresses}`}>
   
          <div className="addresses_items">
            <p>
              Dubai Silicon Oasis
              DDP
              Building A1
              Dubai, United Arab Emirates, UAE
            </p>

            {/* Social Icons */}
            <div className={styles.socialIcons}>
              <a href="tel:+971 56 445 2061">
                <FaPhone /> +971 56 445 2061
              </a>
              <a href="https://wa.me/123456789" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp /> +971 56 445 2061
              </a>
              <a href="mailto:example@example.com">
                <FaEnvelope />finnovatormeweb@gmail.com
              </a>
              
            </div>
             {/* Social Icons at the bottom center */}
      <SocialIcons />
          </div>

        </div>
      )}

    </nav>

  );
};


// Popover Component
const Popover = ({ onClose }) => {
  return (
    <div className={styles.popover}>
      <div className={styles.popoverContent}>
        <div className={styles.serviceBox}>
          <div className={styles.serviceItem}>
            <div className={styles.box}>
            <h5><NavLink to="/information-technology">Information Technology Services:</NavLink></h5>
              <p><NavLink to="/information-technology">Digital Transformation</NavLink></p>
              <p><NavLink to="/information-technology">IT Services Management</NavLink></p>
              <p><NavLink to="/information-technology">Technology Advisory Service</NavLink></p>
              <p><NavLink to="/information-technology">Our Process</NavLink></p>
            </div>
          </div>
          <div className={styles.serviceItem}>
            <div className={styles.box}>
            <h5><NavLink to="/cyber-security"> Cyber Security Services :</NavLink></h5>
              <p><NavLink to="/cyber-security">Security Assessment </NavLink></p>
              <p><NavLink to="/cyber-security">Third-Party Risk Management</NavLink></p>
              <p><NavLink to="/cyber-security">Cybersecurity Technology Evaluation</NavLink></p>
              <p><NavLink to="/cyber-security">Our Process</NavLink></p>
            </div>
          </div>
          <div className={styles.serviceItem}>
            <div className={styles.box}>
            <h5><NavLink to="/risk-governance">Risk Goverance Services :</NavLink></h5>
              <p><NavLink to="/risk-governance">Protect Your Digital Assets</NavLink></p>
              <p><NavLink to="/risk-governance">Mitigate Cyber Risks</NavLink></p>
              <p><NavLink to="/risk-governance">Ensure Compliance</NavLink></p>
              <p><NavLink to="/risk-governance">Our Process</NavLink></p>
            </div>
          </div>
    
          <div className={styles.serviceItem}>
            <div className={styles.box}>
            <h5><NavLink to="/busines-continuity">Business Impact Analysis : </NavLink></h5>
            <p><NavLink to="/busines-continuity">Business Impact Analysis </NavLink></p>
              <p><NavLink to="/busines-continuity">Business Continuity Planning</NavLink></p>
              <p><NavLink to="/busines-continuity">Crisis Management</NavLink></p>
              <p><NavLink to="/busines-continuity">Our Process</NavLink></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};




const Hamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="24" viewBox="0 0 52 24">
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="30"
        height="3"
        rx="2"
        transform="translate(304 47)"
        fill="#fff"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="30"
        height="3"
        rx="2"
        transform="translate(304 67)"
        fill="#fff"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="25"
        height="3"
        rx="2"
        transform="translate(308 57)"
        fill="#fff"
      />
    </g>
  </svg>
);

// New Close Icon Component
const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);

const Logo = ({ scrolled }) => <img src={scrolled ? logo : logo1} alt="Logo" />;

export default Index;
