import React from "react";
import Work from "../../components/OurWork/Work";
import Navbar from "../../components/NavBar";
import Content from "../../components/OurWork/Content";
export default function index() {
  return (
    <div>
      <Navbar />
      <Work />
      <Content />
    </div>
  );
}
