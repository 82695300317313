import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import image1 from "../../../assets/images/hands.jpeg";
export default function Index() {
  const elementsRef = useRef([]);
  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const industries = [
    {
      head: "48 +",
      description: "Applications",
    },
    {
      head: "15 +",
      description: "Years of Market Experience",
    },
    {
      head: "27",
      description: "Team of Experts",
    },
    {
      head: "5",
      description: "Countries Serving",
    },
  ];
  return (
    <div>
      <div className={`${styles.pf_Flex}`}>
        <div className={`${styles.grid}`}>
          {industries.map((industry, index) => (
            <div
              key={index}
              className={`${styles.card} ${styles.fadein}`}
              ref={(el) => (elementsRef.current[index + 1] = el)}
            >
              <div className={`${styles.cardContent}`}>
                <h3 className={`${styles.head}`}>{industry.head}</h3>
                <div>
                  <p className={`${styles.description} sameD`}>
                    {industry.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={` ${styles.fadein} ${styles.imagediv}`}
          ref={(el) => (elementsRef.current[0] = el)}
        >
          <img src={image1} alt="" />
        </div>
      </div>
    </div>
  );
}
// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
