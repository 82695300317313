import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import What from "./pages/WhatWeDo";
import Our from "./pages/OurWork";
import Career from "./pages/Careers";
import Contact from "./pages/Contact";
import DigitalFinance from "./pages/Deails/SolutionsDetailPages/DigitalFinance";
import LoadOrgination from "./pages/Deails/SolutionsDetailPages/LoadOrgination";
import LoanManagement from "./pages/Deails/SolutionsDetailPages/LoanManagement";
import LoanCollections from "./pages/Deails/SolutionsDetailPages/LoanCollections";
import LeasedInventoroyManagement from "./pages/Deails/SolutionsDetailPages/LeasedInventoroyManagement";
import AML_CF from "./pages/Deails/SolutionsDetailPages/AML_CF";
import CreditEngine from "./pages/Deails/SolutionsDetailPages/CreditEngine";
import CustomerServ from "./pages/Deails/SolutionsDetailPages/CustomerServ";
import DocMangSyst from "./pages/Deails/SolutionsDetailPages/DocMangSyst";
import RepossesionMang from "./pages/Deails/SolutionsDetailPages/RepossesionMang";
import SMELoanOrg from "./pages/Deails/SolutionsDetailPages/SMELoanOrg";
import Fiv from "./pages/Deails/SolutionsDetailPages/Fiv";
import TreasuryMang from "./pages/Deails/SolutionsDetailPages/TreasuryMang";
import BussinesContinuity from "./pages/Deails/ServicesDetailPages/BussinesContinuity";
import CyberSecurity from "./pages/Deails/ServicesDetailPages/CyberSecurity";
import InformationTechnology from "./pages/Deails/ServicesDetailPages/InformationTechnology";
import Risk_Governance from "./pages/Deails/ServicesDetailPages/Risk_Governance";
import LegalCaseMang from "./pages/Deails/SolutionsDetailPages/LegalCaseMang";
import WebApplication from "./pages/CaseStudeis/WebApplication";
import MobileApplication from "./pages/CaseStudeis/MobileApplication";
import Flordia from "./pages/CaseStudeis/Florida";
import Preloader from "./components/Utility/Preloader";
import JobApplication from "./pages/JobApplication"; // Ensure the import matches the file name

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the time based on your actual data loading duration

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      {loading ? (
        <Preloader />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Leased-Inventory-Management" element={<LeasedInventoroyManagement />} />
          <Route path="/digital-financing" element={<DigitalFinance />} />
          <Route path="/loan-origination" element={<LoadOrgination />} />
          <Route path="/loan-management" element={<LoanManagement />} />
          <Route path="/loan-collection" element={<LoanCollections />} />
          <Route path="/aml-cf" element={<AML_CF />} />
          <Route path="/credit-engine" element={<CreditEngine />} />
          <Route path="/customer-service" element={<CustomerServ />} />
          <Route path="/document-management" element={<DocMangSyst />} />
          <Route path="/sme-loan-orgination" element={<SMELoanOrg />} />
          <Route path="/treasury-mangement" element={<TreasuryMang />} />
          <Route path="/repossesion-management" element={<RepossesionMang />} />
          <Route path="/collateral-management" element={<Fiv />} />
          <Route path="/legal-case-management" element={<LegalCaseMang />} />
          <Route path="/busines-continuity" element={<BussinesContinuity />} />
          <Route path="/cyber-security" element={<CyberSecurity />} />
          <Route path="/information-technology" element={<InformationTechnology />} />
          <Route path="/risk-governance" element={<Risk_Governance />} />
          <Route path="/about" element={<About />} />
          <Route path="/what-we-do" element={<What />} />
          <Route path="/our-work" element={<Our />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/WebApplication" element={<WebApplication />} />
          <Route path="/MobileApplication" element={<MobileApplication />} />
          <Route path="/Flordia" element={<Flordia />} />
          <Route path="/apply-social-media-exec" element={<JobApplication />} />
          <Route path="/apply-graphic-designer" element={<JobApplication />} />
        </Routes>

      )}
    </Router>
  );
}

export default App;
