import React, { useEffect, useRef } from "react";
import styles from "../../pages/Deails/DetailPages.module.css";
import Navbar from "../../components/NavBar";
import Solutions from "../../components/HomePage/Solutions";
import Services from "../../components/HomePage/Services";
import Contact from "../../components/HomePage/Contact";
import Footer from "../../components/HomePage/Footer";
import two from "../../assets/2065D1.png";
import one from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import three from "../../assets/images/leased inventory/handwriting-ature-contract-with-fountain-pen-generated-by-ai.webp";
import four from "../../assets/images/leased inventory/unlocking-symbol-home-ownership-success-generated-by-ai.webp";
import gsap from "gsap";
import SolutionsPage from "../../components/HomePage/Gallery";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useTextOpacityAnimation from "../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../components/Utility/wrapCharactersInSpan";
import { Link } from "react-scroll";
import style2 from "./styles.module.css";
import video from "../../assets/laptopvideo.mp4";
import Image1 from "../../assets/WebApp/1.png";
import Image2 from "../../assets/WebApp/2.png";
import Image3 from "../../assets/WebApp/3.png";
import Image4 from "../../assets/WebApp/4.png";
gsap.registerPlugin(ScrollTrigger);

export default function WebApplication() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);

  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag && tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else if (tag) {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);

  const rightPicTexts = [
    "Developed under the supervision of a dedicated team of Business Process and Subject Matter Experts, boasting a collective average experience of over 15 years in the GCC Banking & Financing industry.",
  ];

  const leftPicTexts = [""];

  return (
    <div id="scroll-container"  className={`${styles.mj} ${style2.mj}`}>
      <Navbar />
      <div className={styles.top_banner}>
        
        <h1 className={`sameH`}>
          {wrapCharactersInSpan("ALIF” LENDING & FINANCING SOLUTION Web Application")}
        </h1>

      </div>
      <div className={`${styles.detail_main}`}>
        
        <div className={`${style2.itemsflex}`}>
          <div className={`${styles.right_pic} ${style2.right_pic1}`}>
         
            <div className={`${styles.right_pic_items1}`}>
            {/* <h2
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan(
            "“ALIF” LENDING & FINANCING SOLUTION Web Application"
          )}
        </h2> */}
              

              {rightPicTexts.map((text, index) => (
                <p
                  ref={(el) => (elementsRef.current[index + 2] = el)}
                 
                  key={index}
                >
                  {wrapCharactersInSpan(text)}
                </p>
              ))}

              <ul>
                <li ref={(el) => (elementsRef.current[12] = el)}>
                  {wrapCharactersInSpan("Sharia Financing Product Expert")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[13] = el)}>
                  {wrapCharactersInSpan(
                    "Business Process and Operations Expert"
                  )}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[14] = el)}>
                  {wrapCharactersInSpan("Compliance Expert")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[15] = el)}>
                  {wrapCharactersInSpan("Risk & Credit Expert")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[16] = el)}>
                  {wrapCharactersInSpan("Finance & GL Expert")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[17] = el)}>
                  {wrapCharactersInSpan("Digitization Expert ")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[18] = el)}>
                  {wrapCharactersInSpan("Cyber Security Expert")}{" "}
                </li>
                <li ref={(el) => (elementsRef.current[19] = el)}>
                  {wrapCharactersInSpan("Sales & Marketing Expert")}{" "}
                </li>
              </ul>
            </div>
            <div>
              <img src={Image1} alt="" />
            </div>
          </div>
          <div className={`${styles.left_pic} ${style2.left_pic1}`}>
            <div>
              <img src={Image3} alt="" />
            </div>
            <div className={`${styles.left_pic_items1}`}>
              <h2
                ref={(el) => (elementsRef.current[22] = el)}
                id={`${styles.fHh} `}
              >
                {wrapCharactersInSpan("Module")}
              </h2>

              {leftPicTexts.map((text, index) => (
                <p
                  ref={(el) => (elementsRef.current[index + 23] = el)}
                  className={`${styles.fadein}`}
                  key={index}
                >
                  {wrapCharactersInSpan(text)}
                </p>
              ))}
              <ul>
                <li ref={(el) => (elementsRef.current[30] = el)}>
                  {wrapCharactersInSpan("MOBILE APP")}
                </li>
                <li ref={(el) => (elementsRef.current[31] = el)}>
                  {wrapCharactersInSpan("WEB PORTAL")}
                </li>
                <li ref={(el) => (elementsRef.current[32] = el)}>
                  {wrapCharactersInSpan("INTERNAL PORTAL")}
                </li>
                <li ref={(el) => (elementsRef.current[33] = el)}>
                  {wrapCharactersInSpan("EXTERNAL LEAD PROVIDERS")}
                </li>
                <li ref={(el) => (elementsRef.current[34] = el)}>
                  {wrapCharactersInSpan("AGGREGATORS")}
                </li>
                <li ref={(el) => (elementsRef.current[35] = el)}>
                  {wrapCharactersInSpan("PUBLIC CHANNEL MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[36] = el)}>
                  {wrapCharactersInSpan("LOAN ORIGINATION")}
                </li>
                <li ref={(el) => (elementsRef.current[37] = el)}>
                  {wrapCharactersInSpan("LOAN MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[38] = el)}>
                  {wrapCharactersInSpan("LOAN COLLECTION")}
                </li>
                <li ref={(el) => (elementsRef.current[39] = el)}>
                  {wrapCharactersInSpan("CREDIT ENGINE")}
                </li>
                <li ref={(el) => (elementsRef.current[40] = el)}>
                  {wrapCharactersInSpan("ITEM & SUPPLIER MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[41] = el)}>
                  {wrapCharactersInSpan("DOCUMENT MANAGEMENT SYSTEM")}
                </li>
                <li ref={(el) => (elementsRef.current[42] = el)}>
                  {wrapCharactersInSpan("INVENTORY MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[43] = el)}>
                  {wrapCharactersInSpan("INSURANCE MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[44] = el)}>
                  {wrapCharactersInSpan("SME LOAN ORIGINATION")}
                </li>
                <li ref={(el) => (elementsRef.current[45] = el)}>
                  {wrapCharactersInSpan("COLLATERAL MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[46] = el)}>
                  {wrapCharactersInSpan("REPOSSESSION MODULE")}
                </li>
                <li ref={(el) => (elementsRef.current[47] = el)}>
                  {wrapCharactersInSpan("TREASURY MANAGEMENT")}
                </li>
                <li ref={(el) => (elementsRef.current[48] = el)}>
                  {wrapCharactersInSpan("CUSTOMER SERVICE")}
                </li>
                <li ref={(el) => (elementsRef.current[49] = el)}>
                  {wrapCharactersInSpan("FILE ARCHIVING")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`${style2.itemsflex}`}>
          <div className={`${styles.right_pic} ${style2.right_pic1}`}>
            <div className={`${styles.right_pic_items1}`}>
              <h1
                ref={(el) => (elementsRef.current[50] = el)}
                className={`${styles.fadein}`}
                id={`${styles.fHh} sameHh`}
              >
                {/* {wrapCharactersInSpan("Lorem ipsum")} */}
              </h1>

              <ul>
                <li ref={(el) => (elementsRef.current[51] = el)}>
                  {wrapCharactersInSpan(
                    "Omni Channel approach to provide access to internal and public users, using the same base system."
                  )}

                  <ul>
                    <li ref={(el) => (elementsRef.current[52] = el)}>
                      {wrapCharactersInSpan(
                        "Digital Channel (Mobile Application)"
                      )}
                    </li>
                    <li ref={(el) => (elementsRef.current[53] = el)}>
                      {wrapCharactersInSpan("Web Portal (Website)")}
                    </li>
                    <li ref={(el) => (elementsRef.current[54] = el)}>
                      {wrapCharactersInSpan(
                        "Internal Application (Web Application)"
                      )}
                    </li>
                    <li ref={(el) => (elementsRef.current[55] = el)}>
                      {/* {wrapCharactersInSpan()} */}
                    </li>
                  </ul>
                </li>
                <li ref={(el) => (elementsRef.current[56] = el)}>
                  {wrapCharactersInSpan(
                    "Plug and Play approach to design each individual module using secured web APIs and services, which allows easy integration."
                  )}
                </li>
                <li ref={(el) => (elementsRef.current[57] = el)}>
                  {wrapCharactersInSpan(
                    "Provides a digitized approach to cater to customers for multiple Financing products including:"
                  )}

                  <ul>
                    <li ref={(el) => (elementsRef.current[58] = el)}>
                      {wrapCharactersInSpan("Cash Financing")}
                    </li>
                    <li ref={(el) => (elementsRef.current[59] = el)}>
                      {wrapCharactersInSpan("Buy Now Pay Later")}
                    </li>
                    <li ref={(el) => (elementsRef.current[60] = el)}>
                      {wrapCharactersInSpan("  Asset Leasing")}
                    </li>
                    <li ref={(el) => (elementsRef.current[61] = el)}>
                      {wrapCharactersInSpan("Asset Financing")}
                    </li>
                    <li ref={(el) => (elementsRef.current[62] = el)}>
                      {wrapCharactersInSpan("Service Financing")}
                    </li>
                  </ul>
                </li>
                <li ref={(el) => (elementsRef.current[63] = el)}>
                  {wrapCharactersInSpan(
                    "Flexible Configuration for Products, Sub-Products, Schemes (Offers), Pricing, Credit Rules, Credit Score, Policies, Repayment Methods, etc."
                  )}
                </li>
                <li ref={(el) => (elementsRef.current[64] = el)}>
                  {wrapCharactersInSpan(
                    "Configurable Customer Journey (Workflows) for all individual channels."
                  )}
                </li>
                <li ref={(el) => (elementsRef.current[65] = el)}>
                  {wrapCharactersInSpan(
                    "Intra-Module workflow makes it robust to provide collaboration between different departments."
                  )}
                </li>
                <li ref={(el) => (elementsRef.current[66] = el)}>
                  {wrapCharactersInSpan(`Ready-to-use integration hub, which allows communication with
                almost all existing Data providers within KSA, including but not
                limited to:`)}

                  <ul>
                    <li ref={(el) => (elementsRef.current[67] = el)}>
                      {" "}
                      {wrapCharactersInSpan("SIMAH")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[68] = el)}>
                      {wrapCharactersInSpan("ELM")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[69] = el)}>
                      {wrapCharactersInSpan("EMDHA")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[70] = el)}>
                      {wrapCharactersInSpan("NAFITH")}{" "}
                    </li>
                  </ul>
                </li>
                <li ref={(el) => (elementsRef.current[71] = el)}>
                  {wrapCharactersInSpan(
                    "Document Management System for digital management and archiving of Customer and Finance related documents."
                  )}
                </li>
                <li ref={(el) => (elementsRef.current[72] = el)}>
                  {wrapCharactersInSpan(
                    "Flexible and configurable Contracts management, features include but are not limited to:"
                  )}

                  <ul>
                    <li ref={(el) => (elementsRef.current[73] = el)}>
                      {wrapCharactersInSpan("Payments")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[74] = el)}>
                      {wrapCharactersInSpan("Charges")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[75] = el)}>
                      {wrapCharactersInSpan("Reschedule")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[76] = el)}>
                      {wrapCharactersInSpan("Partial Payment")}
                    </li>
                    <li ref={(el) => (elementsRef.current[77] = el)}>
                      {wrapCharactersInSpan("Deferment")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[78] = el)}>
                      {wrapCharactersInSpan("Total Loss")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[79] = el)}>
                      {wrapCharactersInSpan("Loan Status")}{" "}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <img src={Image2} alt="" />
            </div>
          </div>
          <div className={`${styles.left_pic} ${style2.left_pic1}`}>
            <div>
              <img src={Image4} alt="" />
            </div>
            <div className={`${styles.left_pic_items1}`}>
              <h2
                ref={(el) => (elementsRef.current[82] = el)}
                id={`${styles.fHh} `}
              >
                {wrapCharactersInSpan("System Administration")}
              </h2>

              <ul>
                
                

                  <ul>
                    <li ref={(el) => (elementsRef.current[84] = el)}>
                      {wrapCharactersInSpan("User Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[85] = el)}>
                      {wrapCharactersInSpan("Public Application Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[86] = el)}>
                      {wrapCharactersInSpan("Product Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[87] = el)}>
                      {wrapCharactersInSpan("Dynamic Values & List Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[88] = el)}>
                      {wrapCharactersInSpan("Dynamic Rules Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[89] = el)}>
                      {wrapCharactersInSpan("Schemes / Offers Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[90] = el)}>
                      {wrapCharactersInSpan("Credit Rules & Policy Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[91] = el)}>
                      {wrapCharactersInSpan("Suppliers Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[92] = el)}>
                      {wrapCharactersInSpan("Assets / Services Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[93] = el)}>
                      {wrapCharactersInSpan("Workflow Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[94] = el)}>
                      {wrapCharactersInSpan(
                        "Collection Rules & Policy Management"
                      )}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[95] = el)}>
                      {wrapCharactersInSpan("Financial Entries Management")}{" "}
                    </li>
                    <li ref={(el) => (elementsRef.current[96] = el)}>
                      {wrapCharactersInSpan("Incentive Policy Management")}{" "}
                    </li>
                  </ul>
               
              </ul>
            </div>
          </div>
        </div>
        <div className={style2.videoDiv}>
          <video className={style2.video} autoPlay muted loop playsInline>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <button
          ref={(el) => (elementsRef.current[97] = el)}
          className={`${styles.fadein} sameB`}
        >
          <Link to="Contact-Section" smooth={true} duration={500}>
            Get in touch
          </Link>
        </button>
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
