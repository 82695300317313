import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import image1 from "../../../assets/images/Brain.png";
import listimage from "../../../assets/images/list-icn-n.svg";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.Company}`}>
      <div className={`${styles.company_flex}`}>
        <div className={`${styles.content}`}>
          <h2
            ref={(el) => (elementsRef.current[0] = el)}
            className={`${styles.fadein} sameH`}
          >
            Why Finnovator?
          </h2>
          <p
            ref={(el) => (elementsRef.current[1] = el)}
            className={`${styles.fadein} sameP`}
          >
            Embark on a transformative journey with Finnovator IT Solutions.
            Whether you are a small Financing Company or a multinational
            Financial giant, we have the expertise and solutions to propel you
            into the digital future. Our success is underpinned by a dedicated
            team of Business Process and Subject Matter Experts, boasting a
            collective average experience of over 15 years in the GCC Banking &
            Finance sector. We take pride in the diversity and depth of
            knowledge within our team, with each member playing a pivotal role
            in our journey of shaping the future of digital solutions in the
            Banking & Finance sector.
          </p>
        </div>
        <div className={styles.ff}>
          <img
            ref={(el) => (elementsRef.current[3] = el)}
            className={styles.fadein}
            src={image1}
            alt="web-design"
          />
        </div>
      </div>
    </div>
  );
}
