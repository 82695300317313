import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import styles from "./styles.module.css";

const Index = () => {
  const elementsRef = useRef([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    }

    if (!formData.company) {
      newErrors.company = "Company is required";
    }

    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    emailjs
      .send(
        "service_l1oqqdl",
        "template_qgpor83",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          company: formData.company,
          message: formData.message,
        },
        "7niUikV-zazSzHAsJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully");
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          setErrors({});
        },
        (error) => {
          console.log(error.text);
          alert("Error sending email");
        }
      );
  };

  return (
    <div className={styles.main_cont}>
      <div className={styles.contact}>
        <div className={styles.contact_flex}>
          <div>
            <h3 ref={(el) => (elementsRef.current[0] = el)} className={styles.fadein}>
              Get in touch.
            </h3>
            <p ref={(el) => (elementsRef.current[1] = el)} className={styles.fadein}>
              Tell us about you and your project
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <ul>
                {["name", "email", "phone", "company"].map((placeholder, index) => (
                  <li key={index + 2} ref={(el) => (elementsRef.current[index + 2] = el)} className={styles.fadein}>
                    <input
                      type="text"
                      name={placeholder}
                      placeholder={`Your ${placeholder.charAt(0).toUpperCase() + placeholder.slice(1)}`}
                      value={formData[placeholder]}
                      onChange={handleChange}
                    />
                    {errors[placeholder] && <span className={styles.error}>{errors[placeholder]}</span>}
                  </li>
                ))}
                <li ref={(el) => (elementsRef.current[6] = el)} className={styles.fadein}>
                  <textarea
                    name="message"
                    placeholder="What is your requirement?"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <span className={styles.error}>{errors.message}</span>}
                </li>
              </ul>
              <button ref={(el) => (elementsRef.current[7] = el)} className={styles.fadein}>
                Send inquiry
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
