import React, { useEffect, useRef } from "react";
import styles from "../DetailPages.module.css";
import Navbar from "../../../components/NavBar";
import Solutions from "../../../components/HomePage/Solutions";
import Services from "../../../components/HomePage/Services";
import Contact from "../../../components/HomePage/Contact";
import Footer from "../../../components/HomePage/Footer";
import useTextOpacityAnimation from "../../../components/Utility/useTextOpacityAnimation";
import wrapCharactersInSpan from "../../../components/Utility/wrapCharactersInSpan";
import SolutionsPage from "../../../components/HomePage/Gallery";
import gsap from "gsap";
import one from "../../../assets/images/R&G01/business-person-futuristic-business-environment.webp";
import two from "../../../assets/images/R&G01/freepik-export-20240603134557B2SF.webp";
import three from "../../../assets/images/R&G01/freepik-export-20240603135030Hkdr.webp";
import four from "../../../assets/images/R&G01/photorealistic-hands-holding-planet-earth.webp";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import OurProcess from "../../../components/HomePage/OurProcess";

gsap.registerPlugin(ScrollTrigger);

export default function Risk_Governance() {
  const scrollRef = useRef(null);
  const elementsRef = useRef([]);
  useTextOpacityAnimation(elementsRef);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    gsap.to(".full-width-image", {
      opacity: 0.5,
      scrollTrigger: {
        trigger: ".full-width-image",
        start: "top top", // Start animation when image enters the center of the viewport
        end: "bottom center", // End animation when image leaves the center of the viewport
        scrub: true, // Smoothly animates the opacity based on scroll position
      },
    });
  }, []);

  return (
    <div id="scroll-container">
      <Navbar />
      <div className={styles.top_banner}>
        <img
          className="full-width-image"
          src={two}
          alt="Banner"
          style={{ width: "100%", opacity: 1 }}
        />
      </div>
      <div className={`${styles.detail_main}`}>
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className={`${styles.fadein} sameH`}
        >
          {wrapCharactersInSpan("IT Risk & Governance Services")}
        </h1>

        <div className={`${styles.right_pic}`}>
          <div className={`${styles.right_pic_items}`}>
            <h1
              id={`${styles.fHh}`}
              ref={(el) => (elementsRef.current[1] = el)}
              className={`${styles.fadein} sameHh`}
            >
              {wrapCharactersInSpan(
                "Protect Your Digital Assets, Mitigate Cyber Risks, and Ensure Compliance"
              )}
            </h1>
            <p
              ref={(el) => (elementsRef.current[2] = el)}
              className={`${styles.fadein}`}
            >
              {wrapCharactersInSpan(
                "At Finnovator IT Solutions, we recognize the importance of effective IT risk management and governance in ensuring the security, reliability, and compliance of your organization's IT environment. Our IT Risk & Governance Services are designed to help businesses identify, assess, and mitigate IT risks while ensuring alignment with regulatory requirements and industry best practices. With our team of experienced IT risk and governance professionals, we offer a comprehensive range of services to address your organization's specific needs and challenges. Our IT GRC solutions help you implement robust cybersecurity measures, establish effective risk management frameworks, and comply with industry-specific regulations."
              )}
            </p>
            <ul>
              <li
                ref={(el) => (elementsRef.current[3] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan("Safeguard sensitive data and systems")}
              </li>
              <li
                ref={(el) => (elementsRef.current[4] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(
                  "Minimize the impact of potential cyberattacks"
                )}
              </li>
              <li
                ref={(el) => (elementsRef.current[5] = el)}
                className={`${styles.fadein}`}
              >
                {wrapCharactersInSpan(
                  "Avoid costly penalties and reputational damage"
                )}
              </li>
            </ul>
          </div>
          <div className={`${styles.right_pic_items}`}>
            <img
              ref={(el) => (elementsRef.current[6] = el)}
              className={`${styles.fadein}`}
              src={one}
              alt=""
            />
          </div>
        </div>

        <button
          ref={(el) => (elementsRef.current[7] = el)}
          className={`${styles.fadein} sameB`}
        >
          Get in touch
        </button>
        <OurProcess />
        {/* <Solutions /> */}
        <Services />
        <div className="sameHh">Solutions</div>
      </div>
      <SolutionsPage />
      <div className={styles.detail_main}>
        <Contact />
      </div>

      <Footer />
    </div>
  );
}
